export default function (user, project) {

  if (true === project.archived || 'admin' === user.role) {
    return false;
  }

  return (
    (project.project_supervisors || []).includes(user.id) ||
    (project.project_nkks || []).includes(user.id) ||
    (project.project_zkks || []).includes(user.id)
  );
}

<template>
  <div>
    <v-card class="md-12">
      <v-toolbar dense>
        <v-toolbar-title>Uporabniki</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <!-- Send login link dialog -->
        <v-dialog
            persistent
            max-width="600px"
            data-cy="send-login-link-dialog"
            v-model="sendLoginLink.isDialogOpen"
        >
          <v-card>
            <v-card-title>
              <span class="headline">
                Pošlji prijavno povezavo
              </span>
            </v-card-title>
            <v-card-text>
              Uporabnik bo na email prejel povezavo s katero se lahko avtomatsko prijavi v SiteLOG vašega podjetja.
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                  color="primary"
                  flat
                  @click="sendLoginLink.isDialogOpen=false"
              >
                Zapri
              </v-btn>
              <v-btn
                  color="primary"
                  @click="sendLoginLinkViaEmail"
              >
                Pošlji email
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- New user dialogs -->
        <v-dialog
          v-model="newUser.isDialogOpen"
          persistent
          max-width="600px"
          data-cy="new-user-dialog"
        >
          <v-btn
            slot="activator"
            color="primary"
            dark
            class="mb-2"
            data-cy="create-new-user-button"
            @click.stop="newUser.isDialogOpen = true"
          >
            Dodaj uporabnika
          </v-btn>

          <v-card v-show="newUser.showSuccessMessage">
            <v-card-title>
              <span class="headline">
                Dodaj uporabnika
              </span>
            </v-card-title>

            <v-card-text>
              Uporabniku smo na e-mail poslali povezavo s katero se prijavi v aplikacijo.
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                flat
                data-cy="close-new-user-dialog"
                @click="closeNewUserDialog"
              >
                Zapri
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card v-show="!newUser.showSuccessMessage">
            <v-card-title>
              <span class="headline">
                Dodaj uporabnika
              </span>
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
              >
                <v-text-field
                  v-model="newUser.data.first_name"
                  label="Ime"
                  :rule="firstNameRule"
                />
                <v-text-field
                  v-model="newUser.data.last_name"
                  label="Priimek"
                  :rule="lastNameRule"
                />
                <v-text-field
                  v-model="newUser.data.email"
                  type="email"
                  label="Email"
                  :rules="emailRules"
                />
                <v-select
                  v-model="newUser.data.role"
                  label="Uporabniška vloga"
                  :items="available_roles"
                  :rules="userRoleRule"
                  dense
                />
                <v-select
                  v-if="rolesWithProjectSelection.includes(newUser.data.role)"
                  v-model="newUser.data.access_to_projects"
                  label="Dostop do projektov"
                  chips
                  tags
                  dense
                  :items="availableProjects"
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                flat
                @click="newUser.isDialogOpen=false"
              >
                Zapri
              </v-btn>
              <v-btn
                color="primary"
                @click="createNewUser"
              >
                Dodaj uporabnika
              </v-btn>
            </v-card-actions>
            <v-progress-linear
              v-if="newUser.inProgress"
              :indeterminate="true"
            />
          </v-card>
        </v-dialog>

        <!-- Edit user dialog -->
        <v-dialog
          v-model="editUser.isDialogOpen"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">
                Uredi uporabnika
              </span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="editUser.data.display_name"
                disabled
                label="Naziv"
              />
              <v-select
                v-model="editUser.data.role"
                label="Uporabniška vloga"
                :items="available_roles"
                dense
              />
              <v-select
                v-if="rolesWithProjectSelection.includes(editUser.data.role)"
                v-model="editUser.data.access_to_projects"
                label="Dostop do projektov"
                chips
                tags
                dense
                :items="availableProjects"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                flat
                @click="editUser.isDialogOpen=false"
              >
                Zapri
              </v-btn>
              <v-btn
                color="primary"
                @click="changeUserRole"
              >
                Spremeni vlogo uporabnika
              </v-btn>
            </v-card-actions>
            <v-progress-linear
              v-if="editUser.inProgress"
              :indeterminate="true"
            />
          </v-card>
        </v-dialog>

        <!-- Confirm user removal -->
        <v-dialog
          v-model="removeUser.isDialogOpen"
          persistent
          max-width="600px"
        >
          <v-card>
            <v-card-title>
              <span class="headline">
                Odstrani uporabnika
              </span>
            </v-card-title>
            <v-card-text>
              <v-text-field
                v-model="removeUser.data.display_name"
                disabled
                label="Naziv"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                flat
                @click="removeUser.isDialogOpen=false"
              >
                Zapri
              </v-btn>
              <v-btn
                color="error"
                @click="removeUserFromCompany(removeUser.data.user_id)"
              >
                Odstrani uporabnika
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-data-table
          id="list-of-users"
          hide-actions
          :items="users"
          :headers="headers"
        >
          <template
            slot="items"
            slot-scope="props"
          >
            <td>{{ props.item.first_name }}</td>
            <td>{{ props.item.last_name }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.role | user_role | ucfirst }}</td>
            <td class="justify-center layout px-0">
              <v-btn
                  icon
                  class="mx-0"
                  @click="openSendLoginLink(props.item.id)"
              >
                <v-icon color="teal">
                  mail
                </v-icon>
              </v-btn>
              <v-btn
                icon
                class="mx-0"
                @click="openEditUserDialog(props.item.id)"
              >
                <v-icon color="teal">
                  edit
                </v-icon>
              </v-btn>
              <v-btn
                icon
                class="mx-0"
                @click="openRemoveUserDialog(props.item.id)"
              >
                <v-icon color="pink">
                  delete
                </v-icon>
              </v-btn>
            </td>
          </template>
        </v-data-table>

        <br>

        <div class="caption">
          <table>
            <tr>
              <td><b>Administrator</b></td>
              <td>Pregled in urejanje vseh projektov.</td>
            </tr>
            <tr>
              <td><b>Vodja projektov</b></td>
              <td>Pregled in urejanje projektov, ki so mu dodeljeni.</td>
            </tr>
            <tr>
              <td><b>Podizvajalec</b></td>
              <td>Uporabnik lahko ureja in dodaja dnevne liste na projektih, ki so mu dodeljeni. Uporabnik ne more urejati uvodnih listov.</td>
            </tr>
            <tr>
              <td><b>Pregledovalec</b></td>
              <td>Uporabnik lahko samo pregleduje projeke, ki so mu dodeljeni. Uporabnik ne more urejati uvodnih in dnevnih listov.</td>
            </tr>
            <tr>
              <td><b>Nadzornik</b></td>
              <td>Nadzornik lahko podpiše dnevni list in vnese opombe pod točke 6x.</td>
            </tr>
            <tr>
              <td><b>Notranja kontrola kakovosti</b></td>
              <td>Notranja kontrola kakovosti lahko na dnevni list vpisuje opombe pod točko 6x.</td>
            </tr>
            <tr>
              <td><b>Zunanja kontrola kakovosti</b></td>
              <td>Zunanja kontrola kakovosti lahko na dnevni list vpisuje opombe pod točko 6x.</td>
            </tr>
          </table>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { UserRole } from '../model/user/UserRole'

  export default {
    name: 'UsersView',
    data () {
      return {
        available_roles: [
          { text: 'Administrator', value: UserRole.ADMIN },
          { text: 'Vodja projektov', value: UserRole.PROJECT_LEAD },
          { text: 'Podizvajalec', value: UserRole.SUBCONTRACTOR },
          { text: 'Pregledovalec', value: UserRole.REVIEWER },
          { text: 'Nadzornik', value: UserRole.SUPERVISOR },
          { text: 'Notranja kontrola kakovosti', value: UserRole.NKK },
          { text: 'Zunanji kontrola kakovosti', value: UserRole.ZKK },
        ],
        rolesWithProjectSelection: [
          UserRole.PROJECT_LEAD,
          UserRole.SUBCONTRACTOR,
          UserRole.REVIEWER,
          UserRole.SUPERVISOR,
          UserRole.NKK,
          UserRole.ZKK
        ],
        newUser: {
          isDialogOpen: false,
          inProgress: false,
          showSuccessMessage: false,
          data: {
            first_name: '',
            last_name: '',
            email: '',
            role: '',
            access_to_projects: []
          },
        },
        editUser: {
          isDialogOpen: false,
          inProgress: false,
          data: {
            user_id: '',
            display_name: '',
            role: '',
            access_to_projects: []
          }
        },

        removeUser: {
          isDialogOpen: false,
          data: {
            user_id: '',
            display_name: ''
          }
        },

        sendLoginLink: {
          isDialogOpen: false,
          userId: null,
        },

        headers: [
          {
            text: 'Ime',
          },
          {
            text: 'Priimek'
          },
          {
            text: 'Email'
          },
          {
            text: 'Uporabniška vloga'
          },
          {
            text: 'Akcije'
          }
        ],
      };
    },
    computed: {
      users() {
        return this.$store.state.users;
      },
      firstNameRule() {
        return [
          v => !!v || 'Vnesite ime',
        ]
      },
      lastNameRule() {
        return [
          v => !!v || 'Vnesite priimek',
        ]
      },
      emailRules() {
        return [
          v => !!v || 'Vnos e-pošte je obvezen',
          v => /.+@.+/.test(v) || 'Vpišite veljaven e-poštni naslov',
          v => !this.users.some(u => u.email.trim().toLowerCase() === v.trim().toLowerCase()) || 'Uporabnik je že pridružen'
        ]
      },
      userRoleRule() {
        return [
          v => !!v || 'Izberite uporabniško vlogo',
        ]
      },
      availableProjects() {
        return this.$store.state.listOfProjects.map(p => {
          return { text: p.name, value: p.project_id };
        });
      }
    },
    async created() {
      this.$store.dispatch('fetchUsers');
      this.$store.dispatch('fetchProjects');
    },
    methods: {
      openEditUserDialog(userId) {
        const user = this.users.find(u => u.id === userId);
        this.editUser.data.user_id            = user.id;
        this.editUser.data.display_name       = `${user.first_name} ${user.last_name}`;
        this.editUser.data.role               = user.role;

        this.editUser.data.access_to_projects = this
          .$store
          .state
          .listOfProjects
          .filter(p => -1 !== (
              p.project_leads || [])
              .concat(p.project_subcontractors || [])
              .concat(p.project_reviewers || [])
              .concat(p.project_supervisors || [])
              .concat(p.project_nkks || [])
              .concat(p.project_zkks || [])
              .indexOf(userId))
          .map(p => ({ text: p.name, value: p.project_id }))
        ;

        this.editUser.isDialogOpen = true;
      },
      openSendLoginLink(userId) {
        this.sendLoginLink.userId = userId;
        this.sendLoginLink.isDialogOpen = true;
      },
      async sendLoginLinkViaEmail() {
        this.sendLoginLink.inProgress = true;

        await this.$store.dispatch('sendEmailLink', {
          userId: this.sendLoginLink.userId,
        })

        this.sendLoginLink.inProgress = false;
        this.sendLoginLink.isDialogOpen = false;
      },
      async createNewUser() {

        if (this.$refs.form.validate()) {
          this.newUser.inProgress = true;

          await this.$store.dispatch('inviteUser', {
            companyId: this.$store.getters.currentCompany.company_id,
            firstName: this.newUser.data.first_name,
            lastName : this.newUser.data.last_name,
            email    : this.newUser.data.email,
            roleType : this.newUser.data.role
          });

          this.newUser.showSuccessMessage = true;
          this.newUser.inProgress         = false;

          await this.$store.dispatch('fetchUsers');

          const user = this.$store.state.users.find(u => u.email === this.newUser.data.email);
          const roleActions = {
              [UserRole.PROJECT_LEAD]: 'addProjectLead',
              [UserRole.SUBCONTRACTOR]: 'addProjectSubcontractor',
              [UserRole.REVIEWER]: 'addProjectReviewer',
              [UserRole.SUPERVISOR]: 'addProjectSupervisor',
              [UserRole.NKK]: 'addProjectNkks',
              [UserRole.ZKK]: 'addProjectZkks',
          };

          if (roleActions[this.newUser.data.role]) {
            this.newUser.data.access_to_projects.forEach(p => {
              if (p.value) {
                this.$store.dispatch(
                    roleActions[this.newUser.data.role],
                  { projectId: p.value, userId: user.id }
                );
              }
            });
          }
        }
      },
      clearNewUserDialog() {
        this.newUser.showSuccessMessage = false;
        this.newUser.data = {
          first_name: '',
          last_name: '',
          email: '',
          role: ''
        };
      },
      closeNewUserDialog() {
        this.newUser.isDialogOpen = false;
        this.clearNewUserDialog();
      },
      async changeUserRole() {
        this.editUser.isDialogOpen = false;
        this.editUser.inProgress   = true;

        await this.$store.dispatch('changeUserRole', {
          userId: this.editUser.data.user_id,
          roleType: this.editUser.data.role
        });

        await this.$store.dispatch('setUserProjectAccess', {
          userId:     this.editUser.data.user_id,
          userRole:   this.editUser.data.role,
          projectIds: this.editUser.data.access_to_projects.map(p => p.value)
        });

        this.editUser.inProgress = false;
      },
      openRemoveUserDialog(userId) {
        const user = this.users.find(u => u.id === userId);

        this.removeUser.data.user_id      = user.id;
        this.removeUser.data.display_name = `${user.first_name} ${user.last_name}`;
        this.removeUser.isDialogOpen      = true;
      },
      removeUserFromCompany(userId) {
        this.$store.dispatch('removeUserFromCompany', { userId });

        this.removeUser.isDialogOpen = false;
      }
    }
  }
</script>

<style scoped>

</style>

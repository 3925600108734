export default function (user, project) {

  if (true === project.archived) {
    return false;
  }

  if ('admin' === user.role) {
    return true;
  }

  return (project.project_leads || []).includes(user.id);
}

<template>
  <div ref="container">
    <v-progress-linear :indeterminate="true" v-show="loading"></v-progress-linear>
    <div style="text-align: center;">
      <v-btn @click="previousPage()" small>Prejšnja stran</v-btn>
      <v-btn @click="nextPage()" small>Naslednja stran</v-btn>
    </div>
    <div style="text-align: center">
      <span>Stran: <span>{{currentPage}}</span> / <span>{{numOfPages}}</span></span>
    </div>
    <canvas :id="canvasId"></canvas>
  </div>
</template>

<script>
import * as pdfjsLib from 'pdfjs-dist';
import pdfWorkerEntry from 'pdfjs-dist/build/pdf.worker.entry';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfWorkerEntry;

export default {
  name: "PdfPreview",
  props: ['documentUrl'],
  data() {
    return {
      canvasId: 'pdf-preview' + Math.random().toString(),
      loading: true,
      currentPage: 1,
      numOfPages: null,
      pdfRef: null,
    }
  },
  mounted() {
    this.renderPdf();
  },
  methods: {
    async renderPdf() {
      pdfjsLib.getDocument(this.documentUrl).promise
          .then(pdf => {
            this.numOfPages = pdf.numPages;
            this.pdfRef = pdf;
            this.loadPage();
          })
      ;
    },
    async previousPage() {
      if (this.currentPage - 1 > 0) {
        this.currentPage--;
      }

      return this.loadPage();
    },
    async nextPage() {
      if (this.currentPage + 1 <= this.numOfPages) {
        this.currentPage++;
      }

      return this.loadPage();
    },
    async loadPage() {
      this.pdfRef.getPage(this.currentPage).then((page) => {
        const containerWidth = this.$refs.container.clientWidth;
        const widthAtScale1 = 595;
        const scale = containerWidth / widthAtScale1;
        const viewport = page.getViewport({scale: scale});

        // Prepare canvas using PDF page dimensions
        const canvas = document.getElementById(this.canvasId);
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page.render(renderContext);
        this.loading = false;
      });
    },
    downloadPdf() {
      window.open(this.documentUrl, '_blank');
    },
  }
}
</script>

<style scoped>

</style>

import '@babel/polyfill';
import Vue from 'vue';
import './plugins/vuetify';
import './plugins/FullStory';
import App from './App.vue';
import router from './router';
import store from './store/store';
import firebase from 'firebase';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import Lightbox from 'vue-my-photos'
import config from './config';
import ImageUploader from 'vue-image-upload-resize';
import FullStory from "./plugins/FullStory";
import CheckUserCredentials from "./plugins/CheckUserCredentials";
import './plugins/filters';
import migrate from './migrations/to_1_1_0';
import { segmentStoreSubscriber } from './analytics/segment-store-subscriber';

Vue.config.productionTip = false;
Vue.config.performance   = config.PERFORMANCE;

Vue.prototype.$config = config;

if ('development' !== process.env.NODE_ENV) {
  // Initialize Sentry
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://b3a72fa8af53435ab5fa47ea8bddffef@sentry.io/1424504',
    integrations: [new Sentry.Integrations.Vue({
      Vue,
      attachProps: true
    })]
  });
}

// Initialize Firebase
firebase.initializeApp({
  apiKey: config.FIREBASE_APIKEY,
  authDomain: config.FIREBASE_AUTHDOMAIN,
  databaseURL: config.FIREBASE_DATABASEURL,
  projectId: config.FIREBASE_PROJECTID,
  storageBucket: config.FIREBASE_STORAGEBUCKET,
  messagingSenderId: config.FIREBASE_MESSAGINGSENDERID,
  appId: config.FIREBASE_APPID,
});

if ('development' === process.env.NODE_ENV) {
  firebase.firestore().settings({
    host: 'localhost:5002',
    ssl: false,
    // Required for running Cypress tests
    experimentalForceLongPolling: true,
  });


  firebase.auth().useEmulator('http://localhost:9099/');
}

// Init libraries
moment.locale('sl');
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('lightbox', Lightbox);

// Subscribe Segment
store.subscribeAction(segmentStoreSubscriber);

Vue.use(ImageUploader);
Vue.use(FullStory,  { router });
Vue.use(CheckUserCredentials,  { router });

(async () => {
  // Execute migrations
  await migrate(store);

  window.app = new Vue({
    render: h => h(App),
    store,
    router
  }).$mount('#app');
})();

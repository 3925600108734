<template>
  <div>
    <!-- DIALOGS -->
    <v-dialog
      v-model="confirmDeleteProjects"
      max-width="290px"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Potrdite izbris projektov
        </v-card-title>
        <v-card-text>
          <ul>
            <li
              v-for="projectId in selectedProjects"
              :key="projectId"
            >
              {{ projects.find(p => p.project_id === projectId).name }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="confirmDeleteProjects = false"
          >
            Prekliči
          </v-btn>
          <v-btn
            color="error"
            @click="deleteSelectedProjects"
          >
            Izbriši
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmArchiveProjects"
      max-width="290px"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Potrdite arhiviranje projektov
        </v-card-title>
        <v-card-text>
          <ul>
            <li
              v-for="projectId in selectedProjects"
              :key="projectId"
            >
              {{ projects.find(p => p.project_id === projectId).name }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="confirmArchiveProjects = false"
          >
            Prekliči
          </v-btn>
          <v-btn
            color="error"
            data-cy="confirm-archive-selected"
            @click="archiveSelectedProjects"
          >
            Arhiviraj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmUnarchicveProjects"
      max-width="290px"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Potrdite prenos projektov iz arhiva v aktivne projekte
        </v-card-title>
        <v-card-text>
          <ul>
            <li
              v-for="projectId in selectedProjects"
              :key="projectId"
            >
              {{ projects.find(p => p.project_id === projectId).name }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="confirmUnarchicveProjects = false"
          >
            Prekliči
          </v-btn>
          <v-btn
            color="success"
            data-cy="confirm-unarchive-selected"
            @click="unarchiveSelectedProjects"
          >
            Povrni iz arhiva
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card>
      <v-toolbar dense>
        <v-toolbar-title>Projekti</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            v-if="canCreateNewProject"
            icon
            @click="createProjectDialog = true"
            data-cy="open-create-new-project-dialog"
          >
            <v-icon>add</v-icon>
          </v-btn>

          <v-dialog
            v-model="createProjectDialog"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  Nov projekt
                </span>
              </v-card-title>

              <v-card-text>
                <v-text-field
                  v-model="newProject.name"
                  name="naziv_projekta"
                  label="Naziv projekta"
                />
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="blue darken-1"
                  flat
                  @click="createProjectDialog = false"
                >
                  Zapri
                </v-btn>
                <v-btn
                  id="create_new_project"
                  color="blue darken-1"
                  flat
                  @click="createNewProject()"
                >
                  Ustvari projekt
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar-items>
      </v-toolbar>

      <div
        v-if="(!projects || projects.length < 1)"
        class="text-xs-center"
      >
        <v-btn
          v-if="canCreateNewProject && false === filters.showArchived"
          id="open_create_first_project_dialog"
          color="info"
          @click="createProjectDialog = true"
        >
          Ustvari prvi projekt
        </v-btn>

        <p v-if="filters.showArchived">
          <br>
          Trenutno nimate arhiviranih projektov
        </p>
      </div>

      <v-list>
        <v-list-tile>
          <v-list-tile-action v-if="selectedProjects.length > 0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  @click="confirmDeleteProjects = true"
                  v-on="on"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </template>
              <span>Izbriši</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-list-tile-action v-if="selectedProjects.length > 0 && !filters.showArchived">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  data-cy="archive-selected"
                  @click="confirmArchiveProjects = true"
                  v-on="on"
                >
                  <v-icon v-if="!filters.showArchived">
                    archive
                  </v-icon>
                </v-btn>
              </template>
              <span>Arhiviraj</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-list-tile-action v-if="selectedProjects.length > 0 && filters.showArchived">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  icon
                  data-cy="unarchive-selected"
                  @click="confirmUnarchicveProjects = true"
                  v-on="on"
                >
                  <v-icon>unarchive</v-icon>
                </v-btn>
              </template>
              <span>Vrni iz arhiva</span>
            </v-tooltip>
          </v-list-tile-action>

          <v-spacer />

          <v-list-tile-action>
            <v-switch
              v-model="filters.showArchived"
              label="Prikaži arhivirane"
              data-cy="toggle-show-archived"
            />
          </v-list-tile-action>
        </v-list-tile>

        <v-divider />

        <v-treeview
            v-model="selectedProjects"
            :items="projects"
            item-key="project_id"
            selectable
            hoverable
            light
        >
          <template slot="label" slot-scope="props">
            <router-link :to="props.item.url" data-cy="project-row">{{ props.item.name }}</router-link>
          </template>
        </v-treeview>
      </v-list>
    </v-card>
  </div>
</template>

<script>
  import MainView from './MainView';
  import router from '../router';
  import UUID from 'uuid';
  import can_read_project from '../model/user/permissions/can_read_project';
  import can_create_new_project from '@/model/user/permissions/can_create_new_project';

  export default {
    extends: MainView,
    data: () => ({
      createProjectDialog: false,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Projekt', value: 'name' }
      ],
      newProject: {
        name: ''
      },
      selectedProjects: [],
      filters: {
        showArchived: false
      },

      // Delete projects dialog
      confirmDeleteProjects: false,

      // Archive projects dialog
      confirmArchiveProjects: false,
      confirmUnarchicveProjects: false,

    }),
    computed: {
      userRole() {
        return this.$store.getters.currentUser.role;
      },
      user() {
        return this.$store.getters.currentUser
      },
      canCreateNewProject() {
        return can_create_new_project(this.user);
      },
      projects() {
        const currentUser = this.$store.state.currentUser;

        const allSubprojects = [];
        const projects = this
          .$store
          .state
          .listOfProjects
          .filter(p => can_read_project(currentUser, p))
          .filter(p => {
            return this.filters.showArchived ? true === p.archived : !p.archived;
          })
          .map(p => {
            const subprojects = (p.subprojects || [])
              .map((subProjectId) => {
                return this
                    .$store
                    .state
                    .listOfProjects
                    .find(sp => sp.project_id === subProjectId)
              })
              .filter(sp => !!sp)
              .filter(sp => can_read_project(currentUser, sp))
              .filter(sp => this.filters.showArchived ? true === sp.archived : !sp.archived)
            ;

            p.url = '/projects/' + p.project_id;
            p.children = subprojects;
            subprojects.map(sp => sp.project_id).forEach(spid => allSubprojects.push(spid));

            return p;
          })
        ;

        // Make sure we don't show projects that are part of subprojects
        return projects
          .filter(p => !allSubprojects.includes(p.project_id))
        ;
      }
    },
    async created() {
      this.$store.dispatch('fetchProjects');
    },
    methods: {
      goTo(projectId) {
        router.push('/projects/' + projectId);
      },
      devNull() {
        /* Needed to add click handler to some tiles in order to highlight on hover */
        return;
      },
      async createNewProject(name) {
        const projectId = UUID.v1();

        this.createProjectDialog = false;
        await this.$store.dispatch('createNewProject', { name: this.newProject.name, projectId: projectId } );
        this.newProject.name = '';

        this.goTo(projectId);
      },
      async deleteSelectedProjects() {
        this.selectedProjects.forEach(projectId => {
          this.$store.dispatch('deleteProject', projectId);
        });

        this.selectedProjects      = [];
        this.confirmDeleteProjects = false;
      },
      async archiveSelectedProjects() {
        this.selectedProjects.forEach(projectId => {
          this.$store.dispatch('archiveProject', projectId);
        });

        this.selectedProjects       = [];
        this.confirmArchiveProjects = false;
      },
      async unarchiveSelectedProjects() {
        this.selectedProjects.forEach(projectId => {
          this.$store.dispatch('unarchiveProject', projectId);
        });

        this.selectedProjects          = [];
        this.confirmUnarchicveProjects = false;
      }
    }
  }
</script>

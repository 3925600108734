export function segmentStoreSubscriber(action, state){
  if (window.analytics) {
    if (state && state.currentUser && state.currentUser.id) {
      window.analytics.identify(
        state.currentUser.id,
        {
          id: state.currentUser.id,
          email: state.currentUser.email,
          current_company: state.currentCompany.name,
          current_company_id: state.currentCompany.id,
        }
      );
    }

    window.analytics.track(action.type, action.payload);
  }
};



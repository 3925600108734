import can_write_project from './can_write_project';

export default function (user, project) {
  if (can_write_project(user, project)) {
    return true;
  }

  if (true === project.archived) {
    return false;
  }

  if ('admin' === user.role) {
    return true;
  }

  return (project.project_subcontractors || []).includes(user.id);
}

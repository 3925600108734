<template>
  <v-card>
    <v-card-text>
      <div>{{ note }}</div>
      <div v-if="!!signature">
        <img
            style="max-width: 100px;"
            :src="signature"
        >
      </div>
      <div>{{ display_name }} <v-chip>{{ user_role | user_role | ucfirst  }}</v-chip>, {{ timeFormatted }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SignatureNote',
  props: ['display_name', 'user_role', 'signature', 'note', 'created'],

  computed: {
    timeFormatted() {
      const date = moment(this.created).format('dddd, DD.MM.YYYY');
      const time = moment(this.created).format('H:mm');

      return `${date} ob ${time}`;
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <v-dialog
        v-model="deleteDialog.open"
        max-width="290px"
    >
      <v-card>
        <v-card-title
            class="headline grey lighten-2"
            primary-title
        >
          Potrdite izbris priponke
        </v-card-title>
        <v-card-text>
          {{deleteDialog.attachment.original_name}}
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="info"
              @click="deleteDialog.open = false"
          >
            Prekliči
          </v-btn>
          <v-btn
              color="error"
              @click="deleteAttachment"
          >
            Izbriši
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-text-field
        v-if="canUpload"
        ref="attachment"
        v-model="attachment"
        type="file"
        prepend-icon="file"
        label="Priponka"
        :rules="attachmentRules"
        :disabled="!canUpload"
        required
    />

    <v-btn
        v-if="canUpload"
        color="info"
        :loading="uploadInProgress"
        :disabled="!canUpload"
        @click="uploadAttachment"
    >
      Pripni
    </v-btn>

    <ul v-if="attachments">
      <li
          v-for="attachment in attachments"
          :key="attachment.attachment_id"
      >
        <a :href="attachment.download_url">
         {{ attachment.original_name }}
        </a>
        <v-btn
            v-if="canUpload"
            icon
            @click="showConfirmationDialog(attachment)"
        >
          <v-icon>delete</v-icon>
        </v-btn>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'JournalAttachments',
  props: ['projectId', 'date', 'canUpload', 'attachments'],
  data: () => ({
    uploadInProgress: false,
    attachment: null,
    attachmentRules: [
      v => !!v || 'Priponka je obvezna',
    ],

    deleteDialog: {
      open: false,
      attachment: {
        original_name: '',
        attachment_id: '',
      },
    }
  }),
  methods: {
    async uploadAttachment() {
      if (this.$refs.attachment.validate()) {
        this.uploadInProgress = true;

        const projectId  = this.projectId;
        const date       = this.date;
        const attachment = this.$refs.attachment.$refs.input.files[0];

        await this.$store.dispatch('uploadSiteLogAttachment', { projectId, date, attachment });

        this.uploadInProgress = false;
        this.$refs.attachment.reset();
      }
    },
    showConfirmationDialog(attachment) {
      this.deleteDialog.attachment = attachment;
      this.deleteDialog.open = true;
    },
    async deleteAttachment() {
      const projectId    = this.projectId;
      const date         = this.date;
      const attachmentId = this.deleteDialog.attachment.attachment_id;

      await this.$store.dispatch('deleteSiteLogAttachment', { projectId, date, attachmentId });
      this.deleteDialog.open = false;
    }
  },
}
</script>

<style scoped>

</style>
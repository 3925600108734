import Vue from 'vue'
import Router from 'vue-router'
import ProjectsView from './views/ProjectsView'
import ProjectView from './views/ProjectView'
import JournalView from "./views/JournalView"
import RegistrationView from "./views/RegistrationView";
import MainView from './views/MainView';
import LoginView from './views/LoginView';
import EmployeesView from './views/EmployeesView';
import EquipmentView from './views/EquipmentView';
import store from './store/store';
import UsersView from "./views/UsersView";
import ForgottenPassword from './views/ForgottenPassword';
import PublicSigningView from './views/PublicSiteLogSigningView';
import PublicQualityAssurancePreview from '@/views/PublicQualityAssurancePreview';
import MeView from '@/views/MeView';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: MainView,
      meta: {
        requiresAuth: true // enable, when login and registration is enabled
      },
      children: [
        {
          path: '/',
          redirect: '/projects',
          name: 'dashboard',
        },
        {
          path: '/projects/:projectId/journals/:journalDate/:sequence',
          name: 'Dnevni list',
          component: JournalView
        },
        {
          path: '/projects/:projectId',
          name: 'Projekt',
          component: ProjectView,
          children: [
            {
              path: '/projects/:projectId/journals',
              name: 'Dnevniki',
              meta: {
                tab: 1
              }
            },
            {
              path: '/projects/:projectId/pictures',
              name: 'Slike',
              meta: {
                tab: 2
              }
            },
            {
              path: '/projects/:projectId/pictures/imageUrl',
              name: 'Lightbox',
              meta: {
                tab     : 2,
                lightbox: true
              }
            },
            {
              path: '/projects/:projectId/users',
              name: 'Uporabniški dostopi',
              meta: {
                tab: 3
              }
            },
            {
              path: '/projects/:projectId/hierarchy',
              name: 'Hierarhija',
              meta: {
                tab: 4
              }
            },
          ]
        },
        {
          path: '/projects',
          name: 'Projekti',
          component: ProjectsView
        },
        {
          path: '/users',
          name: 'Uporabniki',
          component: UsersView
        },
        {
          path: '/me',
          name: 'Moj profil',
          component: MeView
        },
        {
          path: '/employees',
          name: 'Zaposleni',
          component: EmployeesView
        },
        {
          path: '/equipment',
          name: 'Stroji',
          component: EquipmentView
        }
      ]
    },
    {
      path: '/register',
      name: 'Registracija',
      component: RegistrationView
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginView,
      props: (route) => ({
        emailLogin  : !!route.query.email,
        suggestEmail: route.query.email ? route.query.email.trim().replace(' ', '+') : '',
        companyId   : route.query.company_id || ''
      })
    },
    {
      path: '/forgotten',
      name: 'Forgotten password',
      component: ForgottenPassword
    },
    {
      path: '/sign/site_log',
      name: 'Sign site log',
      component: PublicSigningView
    },
    {
      path: '/sign/qa_preview',
      name: 'Pregled kontrole kakovosti',
      component: PublicQualityAssurancePreview
    }
  ]
});

/**
 * Guard pages that need logged in user
 */
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    const currentUser = store.getters.currentUser;

    if (currentUser.is_authenticated) {
      next();

      return;
    }

    next('/login');

  } else {
    next();
  }
});

export default router;

export class UserRole {
  static get ADMIN()        { return 'admin'; }
  static get PROJECT_LEAD() { return 'project_lead'; }
  static get SUBCONTRACTOR() { return 'subcontractor'; }
  static get REVIEWER()     { return 'reviewer'; }
  static get SUPERVISOR()     { return 'supervisor'; }
  // Notranja kontrolna kakovosti
  static get NKK() { return 'nkk' }
  // Zunanja kontrola kakovosti
  static get ZKK() { return 'zkk' }
}

<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      fixed
      app
    >
      <v-list dense>
        <v-subheader>
          <company-switch />
        </v-subheader>
        <template v-for="item in items">
          <v-layout
            v-if="item.heading"
            :key="item.heading"
            row
            align-center
          />
          <v-list-tile
            :key="item.text"
            @click="goTo(item.href)"
          >
            <v-list-tile-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>
                {{ item.text }}
              </v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </template>

        <v-divider />

        <v-subheader>{{ currentUser.email }}</v-subheader>

        <v-list-tile
            data-cy="me"
            @click="goTo('/me')"
        >
          <v-list-tile-action>
            <v-icon>person</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Moj profil
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>

        <v-list-tile
          data-cy="logout"
          @click="logout"
        >
          <v-list-tile-action>
            <v-icon>logout</v-icon>
          </v-list-tile-action>
          <v-list-tile-content>
            <v-list-tile-title>
              Odjavi se
            </v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      color="primary"
      dark
      app
      fixed
      class="gradient"
    >
      <v-toolbar-title>
        <v-toolbar-side-icon @click.stop="drawer = !drawer" />
      </v-toolbar-title>
      <div style="width: 100%;text-align: center;line-height: 1;">
        <div>
          <span style="font-size: x-large;">
            Site<b>LOG</b>
          </span>
        </div>
        <div>
          <span style="font-size: x-small;">
            We value your time.
          </span>
        </div>
      </div>
    </v-toolbar>
    <v-content>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-content>
  </div>
</template>

<script>
  import router from '../router';
  import CompanySwitch from './components/CompanySwitch';

  export default {
    components: {CompanySwitch},
    data: () => ({
      drawer: null,
      loginDialog : false,
      loginEmail   : null,
      loginPassword: null,
    }),
    computed: {
      currentCompany() {
        return this.$store.getters.currentCompany;
      },
      currentUser() {
        return this.$store.getters.currentUser;
      },
      items() {
        if ('admin' === this.currentUser.role) {
          return [
            { icon: 'business', text: 'Projekti', href: '/projects' },
            { icon: 'people', text: 'Uporabniki', href: '/users'}
          ];
        } else {
          return [
            { icon: 'business', text: 'Projekti', href: '/projects' },
          ];
        }
      }
    },
    methods: {
      goTo(href) {
        router.push(href);
      },
      logout() {
        window.localStorage.clear();
        router.push('/login');
      }
    }
  }
</script>

<style>
  .application {
    font-family: 'Montserrat', sans-serif;
  }

  .gradient {
    background-image: linear-gradient(to right, #4b9ed6 , #6bc5ef);
  }

  .v-tabs__bar {
    background-image: linear-gradient(to right, #4b9ed6 , #6bc5ef);
  }

  .v-toolbar__title {
    font-size: 15px;
  }

  .v-input {
    font-size: 13px;
  }

  h1 {
    font-size: 1.7em;
  }

  .v-input__slot {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 600px) {
    .container {
      padding: 1px;
    }
  }
</style>

<template>
  <div>
    <div>
      <img
          :id="imgId"
          :src="signature"
      >
    </div>

    <v-btn
        v-if="'no' !== show_sign_button"
        color="primary"
        :disabled="!can_edit_project || is_locked"
        @click="openAppropriateSignDialog()"
    >
      Podpiši
    </v-btn>

    <!-- New signature dialog -->
    <v-dialog
        v-model="createSignatureDialogOpen"
        v-if="!is_locked"
        persistent
        fullscreen
        hide-overlay
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="createSignatureDialogOpen = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>Prostor za lastoročni podpis</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
                dark
                flat
                color="error"
                @click="clearSignature()"
            >
              Počisti
            </v-btn>
            <v-btn
                dark
                flat
                @click="sign()"
            >
              Podpiši
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <canvas
            :id="signature_id"
            width="400"
            height="200"
        />
      </v-card>
    </v-dialog>

    <!-- Sign using existing signature dialog -->
    <v-dialog
        v-model="signUsingExistingSignatureDialogOpen"
        max-width="600px"
    >
      <v-card>
        <v-card-title>
        <span class="headline">
          Podpiši
        </span>
        </v-card-title>
        <v-card-text>
          <img
              :src="usersSignature"
          >
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="primary"
              flat
              @click="signUsingExistingSignatureDialogOpen = false"
          >
            Zapri
          </v-btn>
          <v-btn
              color="primary"
              flat
              @click="signUsingExistingSignatureDialogOpen = false; createSignatureDialogOpen = true;"
          >
            Ustvari nov podpis
          </v-btn>
          <v-btn
              color="primary"
              @click="signUsingExistingSignature()"
          >
            Podpiši
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import SignaturePad from 'signature_pad';
import trimCanvas from 'trim-canvas';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Signature',
  props: [
    'project_id',
    'date',
    'signature_id',
    'signature',
    'is_locked',
    'can_edit_project',
    'show_sign_button'
  ],
  data() {
    return {
      signaturePad: null,
      createSignatureDialogOpen: false,
      signUsingExistingSignatureDialogOpen: false,
      imgId: `${this.signature_id}-signed`,
    };
  },
  computed: {
    usersSignature() {
      return this.$store.getters.signature;
    },
  },
  mounted() {
    const canvas = document.getElementById(this.signature_id);
    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
    });

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight - 64;
  },
  methods: {
    openAppropriateSignDialog() {
      if (this.usersSignature) {
        this.signUsingExistingSignatureDialogOpen = true;
      } else {
        this.createSignatureDialogOpen = true;
      }
    },
    clearSignature() {
      this.signaturePad.clear();

      const signatureCanvas = document.getElementById(this.signature_id);
      this.signaturePad= new SignaturePad(signatureCanvas, {
        backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
      });

      signatureCanvas.width = window.innerWidth;
      signatureCanvas.height = window.innerHeight - 64;
    },

    async sign() {
      const signaturePad = this.signaturePad;
      trimCanvas(signaturePad.canvas);
      const base64PngSignature = signaturePad.toDataURL('image/png');
      document.getElementById(this.imgId).src = base64PngSignature;

      await this.$store.dispatch('updateSiteLogField', {
        projectId: this.project_id,
        date: this.date,
        fieldPath: this.signature_id.replace('-', '_'),
        value: base64PngSignature
      });

      if (this.signature_id === 'signature-odgovorni') {
        await this.$store.dispatch('addSignatureLog', {
          projectId: this.project_id,
          date: this.date,
          signature: base64PngSignature
        });

        await this.$store.dispatch('updateSiteLogField', {
          projectId: this.project_id,
          date: this.date,
          fieldPath: 'locked_for_edit',
          value: true,
        });
      }

      this.createSignatureDialogOpen = false;

      // Store signature async
      this.$store.dispatch('saveUserSignature', base64PngSignature);
    },

    async signUsingExistingSignature() {
      await this.$store.dispatch('updateSiteLogField', {
        projectId: this.project_id,
        date: this.date,
        fieldPath: this.signature_id.replace('-', '_'),
        value: this.usersSignature
      });

      if (this.signature_id === 'signature-odgovorni') {
        await this.$store.dispatch('addSignatureLog', {
          projectId: this.project_id,
          date: this.date,
          signature: this.usersSignature,
        });

        await this.$store.dispatch('updateSiteLogField', {
          projectId: this.project_id,
          date: this.date,
          fieldPath: 'locked_for_edit',
          value: true,
        });
      }

      this.signUsingExistingSignatureDialogOpen = false;
    }
  }
}
</script>

<style scoped>

</style>

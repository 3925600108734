<template>
  <div v-if="pageDataLoaded">
    <!-- DIALOGS -->
    <v-dialog
      v-model="confirmDeleteSiteLogs"
      max-width="290px"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Izbris dnevnih listov
        </v-card-title>
        <v-card-text>
          <ul>
            <li
              v-for="date in izbraniDnevnikiForDeletion"
              :key="date"
            >
              {{ formateDate(date) }}
            </li>
          </ul>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="info"
            @click="confirmDeleteSiteLogs = false"
          >
            Prekliči
          </v-btn>
          <v-btn
            color="error"
            @click="deleteSelectedLogs"
          >
            Izbriši
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="projectNameEditDialogOpen"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            Uredi ime projekta
          </span>
        </v-card-title>
        <v-card-text>
          <v-card-text>
            <v-text-field
              v-model="projectNameEdited"
              data-cy="renamed-project-name"
              label="Naziv projekta"
            />
          </v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            flat
            @click="projectNameEditDialogOpen = false"
          >
            Zapri
          </v-btn>
          <v-btn
            data-cy="rename-project"
            color="blue darken-1"
            flat
            @click="changeProjectName"
          >
            Shrani
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <v-layout>
        <v-flex xs11>
          <div>
            <h1>{{ project.name }}</h1>
          </div>
        </v-flex>

        <v-flex
          xs1
          text-xs-right
        >
          <v-btn
            v-if="canEditProject"
            data-cy="open-rename-project-dialog"
            icon
            @click="openProjectNameEditDialog"
          >
            <v-icon>edit</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-tabs
      v-model="openedTab"
      color="primary"
    >
      <v-tab @click="goTo('/projects/' + $route.params.projectId)">
        <span class="white--text">
          Uvodni list
        </span>
      </v-tab>
      <v-tab @click="goTo('/projects/' + $route.params.projectId+ '/journals')">
        <span class="white--text">
          Dnevni listi
        </span>
      </v-tab>
      <v-tab @click="goTo('/projects/' + $route.params.projectId+ '/pictures')">
        <span class="white--text">
          Slike
        </span>
      </v-tab>
      <v-tab
        v-if="canManageProject"
        @click="goTo('/projects/' + $route.params.projectId+ '/users')"
      >
        <span class="white--text">
          Uporabniški dostopi
        </span>
      </v-tab>

      <v-tab
          v-if="canManageProject"
          @click="goTo('/projects/' + $route.params.projectId+ '/hierarchy')"
      >
        <span class="white--text">
          Podprojekti
        </span>
      </v-tab>

      <v-tab-item>
        <v-form>
          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                1. Podatki o objektu, kot so navedeni v gradbenem dovoljenju
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  icon
                  @click="printUvodniList"
                >
                  <v-icon>print</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="objekt__vrsta_objekta"
                :value="objekt.vrsta_objekta"
                :disabled="!canEditProject"
                label="Vrsta objekta glede na namen"
                @change="updateIntroductoryPage($event, 'vrsta_objekta', 1)"
              />
              <v-text-field
                name="objekt__kraj_gradnje"
                :value="objekt.kraj_gradnje"
                :disabled="!canEditProject"
                label="Kraj gradnje"
                @change="updateIntroductoryPage($event, 'kraj_gradnje', 1)"
              />
              <v-text-field
                name="objekt__kraj_za_vreme"
                :value="objekt.kraj_za_vreme"
                :disabled="!canEditProject"
                label="Alternativna lokacija za vreme (v kolikor kraj gradnje ne ustreza)"
                @change="updateIntroductoryPage($event, 'kraj_za_vreme', 1)"
              />
              <v-text-field
                name="objekt__vrsta_gradnje"
                :value="objekt.vrsta_gradnje"
                :disabled="!canEditProject"
                label="Vrsta gradnje"
                @change="updateIntroductoryPage($event, 'vrsta_gradnje', 1)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                2. Podatki o investitorju
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="investitor__naziv"
                :value="investitor.naziv"
                :disabled="!canEditProject"
                label="Naziv in sedež ali ime, priimek in naslov"
                @change="updateIntroductoryPage($event, 'naziv', 2)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                3. Podatki o izvajalcu
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="izvajalec__naziv"
                :value="izvajalec.naziv"
                :disabled="!canEditProject"
                label="Naziv in sedež"
                @change="updateIntroductoryPage($event, 'naziv', 3)"
              />
              <v-textarea
                name="izvajalec__odgovorni"
                :value="izvajalec.odgovorni"
                :disabled="!canEditProject"
                label="Odgovorni vodja s področja"
                auto-grow
                @change="updateIntroductoryPage($event, 'odgovorni', 3)"
              />
              <v-text-field
                name="izvajalec__vodja_gradbisca"
                :value="izvajalec.vodja_gradbisca"
                :disabled="!canEditProject"
                label="Odgovorni vodja gradbišča (če je imenovan)"
                @change="updateIntroductoryPage($event, 'vodja_gradbisca', 3)"
              />
              <v-textarea
                name="izvajalec__vodja_posameznih_del"
                :value="izvajalec.vodja_posameznih_del"
                :disabled="!canEditProject"
                label="Odgovorni vodja posameznih del s področja"
                auto-grow
                @change="updateIntroductoryPage($event, 'vodja_posameznih_del', 3)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                4. Podatki o projektu za pridobitev gradbenega dovoljenja
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="pridobitevGradbenegaDovoljenja__stevilka_in_datum"
                :value="pridobitevGradbenegaDovoljenja.stevilka_in_datum"
                :disabled="!canEditProject"
                label="Identifikacijska številka, datum izdelave, projektant"
                @change="updateIntroductoryPage($event, 'stevilka_in_datum', 4)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                5. Podatki o gradbenem dovoljenju
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="gradbenoDovoljenje__izdajatelj"
                :value="gradbenoDovoljenje.izdajatelj"
                :disabled="!canEditProject"
                label="Naziv organa, ki ga je izdal"
                @change="updateIntroductoryPage($event, 'izdajatelj', 5)"
              />
              <v-textarea
                name="gradbenoDovoljenje__stevilka_in_datum"
                :value="gradbenoDovoljenje.stevilka_in_datum"
                :disabled="!canEditProject"
                label="Številka in datum"
                auto-grow
                @change="updateIntroductoryPage($event, 'stevilka_in_datum', 5)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                6. Podatki o projektu za izvedbo
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="projektZaIzvedbo__stevilka_in_datum"
                :value="projektZaIzvedbo.stevilka_in_datum"
                :disabled="!canEditProject"
                label="Identifikacijska številka in datum izdelave"
                @change="updateIntroductoryPage($event, 'stevilka_in_datum', 6)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                7. Podatki o nadzorniku
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="nadzornik__naziv"
                :value="nadzornik.naziv"
                :disabled="!canEditProject"
                label="Naziv in sedež nadzornika"
                @change="updateIntroductoryPage($event, 'naziv', 7)"
              />
              <v-text-field
                name="nadzornik__odgovorna_oseba"
                :value="nadzornik.odgovorna_oseba"
                :disabled="!canEditProject"
                label="Odgovorni nadzornik"
                @change="updateIntroductoryPage($event, 'odgovorna_oseba', 7)"
              />
              <v-text-field
                name="nadzornik__odgovorna_oseba_posameznih_del"
                :value="nadzornik.odgovorna_oseba_posameznih_del"
                :disabled="!canEditProject"
                label="Odgovorni nadzornik posameznih del z navedbo področja (ime in priimek, strokovni ali poklicni naziv)"
                @change="updateIntroductoryPage($event, 'odgovorna_oseba_posameznih_del', 7)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                8. Pri rekonstrukciji objekta, varovanega na podlagi predpisov o varstvu kulturne dediščine ali pri gradnji na arheološkem območju
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="kulturnaDediscina__naziv"
                :value="kulturnaDediscina.naziv"
                :disabled="!canEditProject"
                label="Naziv in sedež pristojne službe za varstvo kulturne dediščine"
                @change="updateIntroductoryPage($event, 'naziv', 8)"
              />
              <v-text-field
                name="kulturnaDediscina__arheolog"
                :value="kulturnaDediscina.arheolog"
                :disabled="!canEditProject"
                label="Podatki o odgovornem konservatorju oz. arheologu (ime in priimek, strokovni ali poklicni naziv)"
                @change="updateIntroductoryPage($event, 'arheolog', 8)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                9. Predaja projekta za pridobitev gradbenega dovoljenja izvajalcu
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="predajaProjekta__datum"
                :value="predajaProjekta.datum"
                :disabled="!canEditProject"
                label="Datum"
                @change="updateIntroductoryPage($event, 'datum', 11)"
              />
              <v-text-field
                name="predajaProjekta__podpisi"
                :value="predajaProjekta.podpisi"
                :disabled="!canEditProject"
                label="Podpisi"
                @change="updateIntroductoryPage($event, 'podpisi', 11)"
              />
            </v-card-text>
          </v-card>

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                10. Podatki o osebi, odgovorni za sestavo
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="osebaOdgovornaZaSestavo__odgovorni_gradbenega_dnevnika"
                :value="osebaOdgovornaZaSestavo.odgovorni_gradbenega_dnevnika"
                :disabled="!canEditProject"
                label="Gradbenega dnevnika"
                @change="updateIntroductoryPage($event, 'odgovorni_gradbenega_dnevnika', 9)"
              />
              <v-text-field
                name="osebaOdgovornaZaSestavo__odgovorni_obracunskih_mer"
                :value="osebaOdgovornaZaSestavo.odgovorni_obracunskih_mer"
                :disabled="!canEditProject"
                label="Knjige obračunskih izmer"
                @change="updateIntroductoryPage($event, 'odgovorni_obracunskih_mer', 9)"
              />
            </v-card-text>
          </v-card>

          <div
            class="secondary"
            style="height: 3px;"
          />

          <v-card>
            <v-toolbar dense>
              <v-toolbar-title>
                11. Dokumentacija o zagotavljanju varstva in zdravja pri delu na gradbišču
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                name="varstvoInZdravjePriDelu__stevilka_varnostnega_nacrta"
                :value="varstvoInZdravjePriDelu.stevilka_varnostnega_nacrta"
                :disabled="!canEditProject"
                label="Številka varnostnega načrta"
                @change="updateIntroductoryPage($event, 'stevilka_varnostnega_nacrta', 10)"
              />
              <v-text-field
                name="varstvoInZdravjePriDelu__izvajalec"
                :value="varstvoInZdravjePriDelu.izvajalec"
                :disabled="!canEditProject"
                label="Izvajalec, pooblaščen za ureditev gradbišča v skladu z varnostnim načrtom"
                @change="updateIntroductoryPage($event, 'izvajalec', 10)"
              />
              <v-text-field
                name="varstvoInZdravjePriDelu__koordinator_za_varnost"
                :value="varstvoInZdravjePriDelu.koordinator_za_varnost"
                :disabled="!canEditProject"
                label="Koordinator za varnost (ime in priimek, strokovni ali poklicni naziv)"
                @change="updateIntroductoryPage($event, 'koordinator_za_varnost', 10)"
              />
            </v-card-text>
          </v-card>
        </v-form>
      </v-tab-item>
      <v-tab-item>
        <v-card>
          <v-toolbar dense>
            <v-toolbar-title>Dnevni listi</v-toolbar-title>
            <v-spacer />

            <v-toolbar-items class="hidden-sm-and-down">
              <v-switch
                  class="auto-create-sitelogs-switch"
                  label="Avtomatsko kreiraj dnevne liste"
                  @change="enableOrDisableAutomaticSiteLogs($event)"
                  value
                  :input-value="project.automatic_site_logs_enabled"
                  v-if="false && canEditProject"
              >
              </v-switch>
            </v-toolbar-items>

            <v-dialog
              ref="dialog"
              v-model="createDnevniListModal"
              :return-value.sync="createDnevniListDate"
              persistent
              lazy
              full-width
              width="290px"
            >
              <v-btn
                v-if="canWriteSitelogs"
                slot="activator"
                icon
                @click.stop="createDnevniListModal = true"
              >
                <v-icon>add</v-icon>
              </v-btn>

              <v-date-picker
                v-model="createDnevniListDate"
                first-day-of-week="1"
                locale="sl"
                scrollable
              >
                <v-spacer />
                <v-btn
                  flat
                  color="primary"
                  @click="createDnevniListModal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  flat
                  color="primary"
                  @click="createDnevniList(createDnevniListDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-toolbar>

          <v-list>
            <v-list-tile>
              <v-list-tile-action>
                <v-checkbox
                    @change="toggleSiteLogSelection($event)"
                ></v-checkbox>
              </v-list-tile-action>

              <v-list-tile-action>
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                    >
                      filter_alt
                    </v-icon>

                    <span v-on="on" v-show="sitelogFilters.vsi">Vsi <v-icon>arrow_drop_down</v-icon></span>
                    <span v-on="on" v-show="sitelogFilters.nepodpisani">Nepodpisani <v-icon>arrow_drop_down</v-icon></span>
                    <span v-on="on" v-show="sitelogFilters.podpisani">Podpisani <v-icon>arrow_drop_down</v-icon></span>
                  </template>
                  <v-list>
                    <v-list-tile
                        @click="filterShowAllSiteLogs()"
                    >
                      <span v-show="sitelogFilters.vsi">✓ </span> Vsi
                    </v-list-tile>
                    <v-list-tile
                        @click="filterShowUnsignedSiteLogs()"
                    >
                      <span v-show="sitelogFilters.nepodpisani">✓ </span> Nepodpisani
                    </v-list-tile>
                    <v-list-tile
                        @click="filterShowSignedSiteLogs()"
                    >
                      <span v-show="sitelogFilters.podpisani">✓ </span> Podpisani
                    </v-list-tile>
                  </v-list>
                </v-menu>
              </v-list-tile-action>

              <v-list-tile-action v-if="izbraniDnevniki.length > 0">
                <v-btn
                  icon
                  @click="confirmDeleteSiteLogs = true"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-tile-action>

              <v-list-tile-action v-if="izbraniDnevniki.length > 0">
                <div v-if="canEditProject">
                  <SendJournalToSignature
                      v-bind:projectId="projectId"
                      v-bind:date="izbraniDnevniki"
                      v-bind:sequence="izbraniDnevnikiSequences"
                      v-bind:supervisors="projectSupervisors"
                      v-bind:nkks="projectNkks"
                      v-bind:zkks="projectZkks"
                      v-bind:isSigned="false"
                  ></SendJournalToSignature>
                </div>
              </v-list-tile-action>

              <v-list-tile-action v-if="izbraniDnevniki.length > 0">
                <v-btn
                  icon
                  @click="exportSelectedLogs"
                >
                  <v-icon>print</v-icon>
                </v-btn>
              </v-list-tile-action>

              <v-list-tile-action v-if="izbraniDnevniki.length > 0">
                <v-switch
                  v-model="printWithImages"
                  label="Natisni s slikami"
                />
              </v-list-tile-action>

              <v-spacer />

              <v-list-tile-content>
                <v-list-tile-sub-title class="text-lg-right">
                  Skupno št. listov: {{ dnevniki.length }}
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>

            <v-divider />

            <v-list-tile
              v-for="dnevnik in dnevniki"
              :key="dnevnik.id"
              @click="devNull"
            >
              <v-list-tile-action>
                <v-checkbox
                  v-if="canEditProject"
                  v-model="izbraniDnevniki"
                  :value="dnevnik.date"
                />
              </v-list-tile-action>

              <v-list-tile-content @click="goTo('/projects/' + dnevnik.project_id + '/journals/' + dnevnik.date + '/' + dnevnik.sequence)">
                <v-list-tile-title>
                  <span v-show="dnevnik.locked_for_edit">🔏</span>
                  {{ dnevnik.display_name }}
                </v-list-tile-title>
                <v-list-tile-sub-title>
                  <span v-show="dnevnik.signed_by.nadzornik">✓ Podpisal: Nadzornik</span>
                  <span v-show="dnevnik.signed_by.sestavljalec"> ✓ Podpisal: Sestavljalec</span>
                  <span v-show="dnevnik.signed_by.odgovorni"> ✓ Podpisal: Odgovorni</span>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card>
          <v-toolbar dense>
            <v-toolbar-title>Slike</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                @click="printPhotos"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-text v-if="canAddPhotos">
            <v-form ref="imageUploadForm">
              <image-uploader
                ref="image"
                prepend-icon="image"
                :debug="0"
                :max-width="2048"
                :quality="0.9"
                :auto-rotate="true"
                :preview="true"
                accept="image/*"
                do-not-resize="['gif', 'svg']"
                required
                :rules="imageRules"
                label="Slika"
                output-format="blob"
                @input="setImage"
              />
              <v-text-field
                :value="computedImageDate"
                label="Datum slike"
                prepend-icon="event"
                :rules="imageDateRules"
                readonly
                required
                @focus="newImageDatePickerOpen = true"
              />
              <v-date-picker
                v-show="newImageDatePickerOpen"
                v-model="imageDate"
                no-title
                locale="sl"
                first-day-of-week="1"
                @input="newImageDatePickerOpen = false"
              />
              <v-text-field
                v-model="imageDescription"
                label="Opis slike"
              />

              <v-btn
                color="info"
                :loading="uploadInProgress"
                @click="uploadImage"
              >
                Naloži sliko
              </v-btn>
            </v-form>
          </v-card-text>

          <v-container
            v-for="(images, date) in siteLogImagesGroupedByDate"
            :key="date"
            grid-list-sm
            fluid
          >
            <lightbox
              id="lightbox"
              ref="lightbox"
              :images="lightboxImages"
              :timeout-duration="5000"
            />

            <h2>{{ date | humanReadableDate }}</h2>
            <v-layout
              row
              wrap
            >
              <v-flex
                v-for="photo in images"
                :key="photo.image_id"
                xs4
                d-flex
              >
                <v-card
                  class="mx-auto"
                >
                  <v-card-text>
                    <v-img
                      :src="photo.thumbs[256][0]"
                      aspect-ratio="1"
                      class="grey lighten-2"
                      @click="showLightbox(photo)"
                    >
                      <v-layout
                        slot="placeholder"
                        fill-height
                        align-center
                        justify-center
                        ma-0
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        />
                      </v-layout>
                    </v-img>
                  </v-card-text>

                  <v-card-actions v-if="canEditProject">

                    <v-btn
                        fab
                        @click="openEditImageDialog(photo)"
                    >
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn
                        fab
                        @click="downloadImage(photo)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>

        <!-- DIALOGS -->
        <v-layout
          row
          justify-center
        >
          <v-dialog
            v-model="imageEdit.dialogOpen"
            persistent
            max-width="600px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  Urejanje slike
                </span>
              </v-card-title>
              <v-card-text>
                <v-container grid-list-md>
                  <v-text-field
                    :value="computedEditableImageDate"
                    label="Datum slike"
                    prepend-icon="event"
                    :rules="imageDateRules"
                    readonly
                    required
                    @focus="imageEdit.dateDialogOpen = true"
                  />
                  <v-date-picker
                    v-show="imageEdit.dateDialogOpen"
                    v-model="imageEdit.imageData.date"
                    no-title
                    locale="sl"
                    first-day-of-week="1"
                    @input="imageEdit.dateDialogOpen = false"
                  />
                  <v-text-field
                    v-model="imageEdit.imageData.description"
                    label="Opis slike"
                  />
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="red darken-1"
                  flat
                  @click="deleteImage"
                >
                  Izbriši sliko
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  flat
                  @click="imageEdit.dialogOpen = false"
                >
                  Zapri
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  flat
                  @click="updateImage()"
                >
                  Shrani
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-tab-item>

      <v-tab-item>
        <v-card>
          <v-toolbar dense>
            <v-toolbar-title>Uporabniki</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-select
              :items="possibleProjectLeads"
              :value="projectLeads"
              item-value="id"
              item-text="display_name"
              item-disabled="disabled"
              label="Uporabniki, ki lahko urejajo projekt"
              chips
              tags
              @change="updateProjectLeads"
            />
          </v-card-text>

          <v-card-text>
            <v-select
                :items="possibleProjectSubcontractors"
                :value="projectSubcontractors"
                item-value="id"
                item-text="display_name"
                item-disabled="disabled"
                label="Podizvajalci"
                chips
                tags
                @change="updateProjectSubcontractors"
            />
          </v-card-text>

          <v-card-text>
            <v-select
              :items="possibleProjectReviewers"
              :value="projectReviewers"
              item-value="id"
              item-text="display_name"
              item-disabled="disabled"
              label="Uporabniki, ki lahko samo pregledujejo projekt"
              chips
              tags
              @change="updateProjectReviewers"
            />
          </v-card-text>

          <v-card-text>
            <v-select
                :items="possibleProjectSupervisors"
                :value="projectSupervisors"
                item-value="id"
                item-text="display_name"
                item-disabled="disabled"
                label="Nadzorniki"
                chips
                tags
                @change="updateProjectSupervisors"
            />
          </v-card-text>

          <v-card-text>
            <v-select
                :items="possibleProjectNkks"
                :value="projectNkks"
                item-value="id"
                item-text="display_name"
                item-disabled="disabled"
                label="Notranja kontrola kakovosti"
                chips
                tags
                @change="updateProjectNkks"
            />
          </v-card-text>

          <v-card-text>
            <v-select
                :items="possibleProjectZkks"
                :value="projectZkks"
                item-value="id"
                item-text="display_name"
                item-disabled="disabled"
                label="Zunanja kontrola kakovosti"
                chips
                tags
                @change="updateProjectZkks"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card>
          <v-toolbar dense>
            <v-toolbar-title>Podprojekti</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-select
                :items="possibleProjectsForHierarchy"
                :value="projectHierarchy"
                item-value="project_id"
                item-text="name"
                label="Podprojekti"
                chips
                tags
                @change="updateProjectHierarchy"
            />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style>
  .auto-create-sitelogs-switch {
    margin: 8px !important;
  }

  .lightbox-caption {
    font-size: 28px;
  }

  .lightbox-arrow-left-icon svg, .lightbox-arrow-right-icon svg {
    width: 48px;
    height: 48px;
  }
</style>

<script>
  import MainView from './MainView';
  import router from '../router';
  import moment from 'moment';
  import _ from 'lodash';
  import can_write_project from '../model/user/permissions/can_write_project';
  import {UserRole} from '@/model/user/UserRole';
  import SendJournalToSignature from '@/views/components/SendJournalToSignature';
  import can_create_new_project from '@/model/user/permissions/can_create_new_project';
  import can_add_photos from '../model/user/permissions/can_add_photos';
  import can_write_sitelog from '../model/user/permissions/can_write_sitelog';

  export default {
    components: { SendJournalToSignature },
    filters: {
      humanReadableDate: (date) => {
        return moment(date).format('dddd, DD.MM.YYYY');
      }
    },
    extends: MainView,
    data () {
      return {
        pageDataLoaded: false,
        openedTab: this.$route.meta.tab || 0,
        createDnevniListModal: false,
        createDnevniListDate: (new Date()).toISOString().substr(0, 10),
        projectNameEditDialogOpen: false,
        projectNameEdited: '',
        journalHeaders: [
          { text: 'ID', value: 'id' },
          { text: 'Datum', value: 'date' }
        ],
        izbraniDnevniki: [],
        printWithImages: true,
        sitelogFilters: {
          vsi: true,
          nepodpisani: false,
          podpisani: false,
        },

        // Image upload,
        uploadInProgress: false,
        imageDate: moment().format('YYYY-MM-DD'),
        imageDateRules: [
          v => !!v || 'Datum je obvezen',
        ],
        image: null,
        imageName: null,
        imageRules: [
          v => !!v || 'Slika je obvezna',
        ],
        imageDescription: null,
        newImageDatePickerOpen: false,

        imageEdit: {
          dialogOpen    : false,
          dateDialogOpen: false,
          imageData     : {}
        },

        // Lightbox
        checkLightBoxOpenInterval: null,

        // Delete sitelog dialog
        confirmDeleteSiteLogs: false
      }
    },
    computed: {
      izbraniDnevnikiForDeletion() {
        return this
            .izbraniDnevniki
            .filter(date => !this.dnevniki.find(d => d.date === date).locked_for_edit)
        ;
      },
      izbraniDnevnikiSequences() {
        return this.izbraniDnevniki.map((date) => {
          return this.dnevniki.find(d => d.date === date).sequence;
        });
      },
      canAddPhotos() {
        return can_add_photos(this.$store.state.currentUser, this.project);
      },
      canWriteSitelogs() {
        const currentUser = this.$store.state.currentUser;

        return can_write_sitelog(currentUser, this.project);
      },
      canEditProject() {
        const currentUser = this.$store.state.currentUser;

        return can_write_project(currentUser, this.project);
      },
      canManageProject() {
        return can_create_new_project(this.$store.state.currentUser);
      },
      projectId() {
        return this.$route.params.projectId;
      },
      project() {
        return this.$store.getters.projectSummary(this.$route.params.projectId);
      },
      companyId() {
        return this.$store.state.currentCompany.company_id;
      },
      objekt() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 1);
      },
      investitor() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 2);
      },
      izvajalec() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 3);
      },
      pridobitevGradbenegaDovoljenja() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 4);
      },
      gradbenoDovoljenje() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 5);
      },
      projektZaIzvedbo() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 6);
      },
      nadzornik() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 7);
      },
      kulturnaDediscina() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 8);
      },
      osebaOdgovornaZaSestavo() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 9);
      },
      varstvoInZdravjePriDelu() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 10);
      },
      predajaProjekta() {
        return this.$store.getters.uvodniListSection(this.$route.params.projectId, 11);
      },
      dnevniki() {
        let startSequence = 1;

        return this.$store.getters
          .seznamDnevnihListov(this.$route.params.projectId)
          .map(d => {
            const nextSequence = _.padStart(startSequence++, 3, '0');

            return {
              ...d,
              sequence    : nextSequence,
              display_name: nextSequence + ' ' + moment(d.date).format('dddd, DD.MM.YYYY')
            };
          })
          .filter(d => {
            if (this.sitelogFilters.nepodpisani) {
              return !(Object.values(d.signed_by).every(isSigned => isSigned));
            }

            if (this.sitelogFilters.podpisani) {
              return (Object.values(d.signed_by).every(isSigned => isSigned));
            }

            return true;
          })
          .reverse()
        ;
      },
      siteLogImagesGroupedByDate() {
        return _.groupBy(
          _.orderBy(this.$store.getters.siteLogImages(this.$route.params.projectId), ['date'], ['desc']),
          'date'
        );
      },
      computedImageDate() {
        return moment(this.imageDate).format('DD.MM.YYYY');
      },
      computedEditableImageDate() {
        return moment(this.imageEdit.imageData.date).format('DD.MM.YYYY');
      },

      // Lightbox
      lightboxImages() {
        return _.orderBy(this.$store.getters.siteLogImages(this.$route.params.projectId), ['date'], ['desc'])
          .map(i => {
            const humanReadableDate = moment(i.date).format('dddd, DD.MM.YYYY');

            return {
              name: i.thumbs[1024][0],
              alt : `${humanReadableDate}: ${i.description || 'Ni opisa'}`,
            };
          })
        ;
      },

      // User management
      possibleProjectLeads() {
        return this
          .$store
          .getters
          .possibleProjectLeads(this.projectId)
          .map(u => {
            return {
              ...u,
              display_name: `${u.first_name} ${u.last_name}`
            };
          })
        ;
      },
      projectLeads() {
        return this
          .$store
          .getters
          .projectLeads(this.projectId)
          .map(u => {
            return {
              ...u,
              display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
              disabled    : u.role === 'admin'
            };
          })
        ;
      },
      possibleProjectSubcontractors() {
        return this
            .$store
            .getters
            .possibleProjectSubcontractors(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name}`
              };
            })
            ;
      },
      projectSubcontractors() {
        return this
            .$store
            .getters
            .projectSubcontractors(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
            ;
      },
      possibleProjectReviewers() {
        return this
          .$store
          .getters
          .possibleProjectReviewers(this.projectId)
          .map(u => {
            return {
              ...u,
              display_name: `${u.first_name} ${u.last_name}`
            };
          })
        ;
      },
      projectReviewers() {
        return this
          .$store
          .getters
          .projectReviewers(this.projectId)
          .map(u => {
            return {
              ...u,
              display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
              disabled    : u.role === 'admin'
            };
          })
        ;
      },
      possibleProjectSupervisors() {
        return this
            .$store
            .getters
            .possibleProjectSupervisors(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name}`
              };
            })
          ;
      },
      projectSupervisors() {
        return this
            .$store
            .getters
            .projectSupervisors(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
          ;
      },
      possibleProjectNkks() {
        return this
            .$store
            .getters
            .possibleProjectNkks(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name}`
              };
            })
        ;
      },
      projectNkks() {
        return this
            .$store
            .getters
            .projectNkks(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
            ;
      },
      possibleProjectZkks() {
        return this
            .$store
            .getters
            .possibleProjectZkks(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name}`
              };
            })
            ;
      },
      projectZkks() {
        return this
            .$store
            .getters
            .projectZkks(this.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
            ;
      },
      possibleProjectsForHierarchy() {
        return this
          .$store
          .getters
          .listOfProjects()
          .filter(p => p.project_id !== this.projectId)
      },
      projectHierarchy() {
        return (this.project.subprojects || [])
          .map(projectId => {
            return this
                .$store
                .getters
                .listOfProjects()
                .find(p => p.project_id === projectId);
          })
        ;
      }
    },
    watch: {
      '$route': function (route) {
        if (!this.$refs.lightbox) return;

        if ('string' === typeof route.query.imageUrl) {
          this.$refs.lightbox[0].show(route.query.imageUrl);
          this.checkLightBoxIfOpen();
        } else {
          this.$refs.lightbox[0].hide();

          clearInterval(this.checkLightBoxOpenInterval);
        }
      }
    },
    async created() {
      await Promise.all([
        this.$store.dispatch('fetchProjects'),
        this.$store.dispatch('fetchSiteLogs', this.$route.params.projectId),
        this.$store.dispatch('fetchSiteLogImages', this.$route.params.projectId),
        this.$store.dispatch('fetchUsers'),
      ]);

      this.pageDataLoaded = true;
    },
    mounted() {
      if ('string' === typeof this.$route.query.imageUrl) {
        this.$refs.lightbox[0].show(this.$route.query.imageUrl);
        this.checkLightBoxIfOpen();
      }
    },
    methods: {
      goTo(href) {
        router.push(href);
      },
      devNull() {
        /* Needed to add click handler to some tiles in order to highlight on hover */
        return;
      },
      formateDate(date) {
        return moment(date).format('dddd, DD.MM.YYYY');
      },
      openProjectNameEditDialog() {
        this.projectNameEdited         = this.project.name;
        this.projectNameEditDialogOpen = true;
      },
      async changeProjectName() {
        const projectId = this.$route.params.projectId;
        const name      = this.projectNameEdited;

        await this.$store.dispatch('changeProjectName', { projectId, name });

        this.projectNameEditDialogOpen = false;
      },
      async createDnevniList(forDate) {
        this.createDnevniListModal = false;
        await this.$store.dispatch('createNewSiteLog', {
          projectId: this.$route.params.projectId,
          date: forDate
        });

        this.izbraniDnevniki = [];
      },
      async updateIntroductoryPage(value, fieldPath, sectionNumber) {
        const projectId = this.$route.params.projectId;
        this.$store.dispatch('updateIntroductoryPageSection', { value, projectId, fieldPath, sectionNumber });
      },
      toggleSiteLogSelection(isChecked) {
        if (isChecked) {
          this.izbraniDnevniki = this.dnevniki.map(d => d.date);
        } else {
          this.izbraniDnevniki = [];
        }
      },
      filterShowAllSiteLogs() {
        this.izbraniDnevniki = [];

        this.sitelogFilters = {
          vsi: true,
          nepodpisani: false,
          podpisani: false,
        };
      },
      filterShowUnsignedSiteLogs() {
        this.izbraniDnevniki = [];

        this.sitelogFilters = {
          vsi: false,
          nepodpisani: true,
          podpisani: false,
        };
      },
      filterShowSignedSiteLogs() {
        this.izbraniDnevniki = [];

        this.sitelogFilters = {
          vsi: false,
          nepodpisani: false,
          podpisani: true,
        };
      },
      async deleteSelectedLogs() {
        await Promise.all(this
            .izbraniDnevniki
            .map(date => {
              return this.$store.dispatch('deleteSiteLog', {
                projectId: this.$route.params.projectId,
                date     : date
              });
        }));

        this.izbraniDnevniki       = [];
        this.confirmDeleteSiteLogs = false;
      },
      async exportSelectedLogs() {
        const exportParams =
          _.orderBy(
            this
              .dnevniki
              .filter(d => this.izbraniDnevniki.includes(d.date))
            ,['date'], ['asc'])
          .map(d => `${d.date},${d.sequence}`)
          .join(';')
        ;

        const url = `${this.$config.API_PDF_EXPORT_BASE}/dnevni-list/${this.$route.params.projectId}/${exportParams}?withPhotos=${this.printWithImages}`;

        window.open(url, '_blank');
      },
      async uploadImage() {
        if (this.$refs.imageUploadForm.validate()) {
          this.uploadInProgress = true;

          const projectId   = this.$route.params.projectId;
          const image       = this.image;
          const imageName   = this.imageName;
          const imageDate   = this.imageDate;
          const description = this.imageDescription;

          await this.$store.dispatch('uploadSiteLogImage', {
            projectId,
            image,
            imageName,
            imageDate,
            description
          });

          this.uploadInProgress = false;
          this.imageDescription = '';
        }
      },
      showLightbox(image) {
        const imageUrl = image.thumbs[1024][0];
        this.goTo(`${this.$route.path}?imageUrl=${encodeURIComponent(imageUrl)}`);
      },
      checkLightBoxIfOpen() {
        this.checkLightBoxOpenInterval = setInterval(() => {
          if (this.$refs.lightbox && !this.$refs.lightbox[0].visible) {
            this.goTo(this.$route.path);
          }
        }, 1000);
      },
      openEditImageDialog(image) {
        this.imageEdit.dialogOpen = true;
        this.imageEdit.imageData  = Object.assign({}, image);
      },
      downloadImage(image) {
        window.open(image.download_url, '_blank');
      },
      setImage(image) {
        this.image     = image;
        this.imageName = document.getElementById('fileInput').files[0].name;
      },
      async updateImage() {
        this.imageEdit.dialogOpen = false;

        const projectId   = this.$route.params.projectId;
        const imageId     = this.imageEdit.imageData.image_id;
        const date        = this.imageEdit.imageData.date;
        const description = this.imageEdit.imageData.description;

        await this.$store.dispatch('updateSiteLogImage', {
          projectId,
          imageId,
          date,
          description
        });
      },
      async deleteImage() {
        this.imageEdit.dialogOpen = false;

        const projectId = this.$route.params.projectId;
        const imageId   = this.imageEdit.imageData.image_id;

        await this.$store.dispatch('deleteSiteLogImage', {
          projectId,
          imageId
        });
      },
      async printUvodniList() {
        const url = `${this.$config.API_PDF_EXPORT_BASE}/uvodni-list/${this.$route.params.projectId}`;

        window.open(url, '_blank');
      },

      async printPhotos() {
        const url = `${this.$config.API_PDF_EXPORT_BASE}/photos/${this.companyId}/${this.$route.params.projectId}`;

        window.open(url, '_blank');
      },

      async updateProjectLeads(selectedItems) {
        await this.$store.dispatch('setProjectLeads', {
          projectId: this.projectId,
          userIds  : selectedItems.filter(u => u.role !== 'admin').map(u => u.id)
        })
      },
      async updateProjectSubcontractors(selectedItems) {
        await this.$store.dispatch('setProjectSubcontractors', {
          projectId: this.projectId,
          userIds  : selectedItems.filter(u => u.role === UserRole.SUBCONTRACTOR).map(u => u.id)
        })
      },
      async updateProjectReviewers(selectedItems) {
        await this.$store.dispatch('setProjectReviewers', {
          projectId: this.projectId,
          userIds  : selectedItems.filter(u => u.role === 'reviewer').map(u => u.id)
        })
      },
      async updateProjectSupervisors(selectedItems) {
        await this.$store.dispatch('setProjectSupervisors', {
          projectId: this.projectId,
          userIds  : selectedItems.filter(u => u.role === UserRole.SUPERVISOR).map(u => u.id)
        });
      },
      async updateProjectNkks(selectedItems) {
        await this.$store.dispatch('setProjectNkks', {
          projectId: this.projectId,
          userIds  : selectedItems.filter(u => u.role === UserRole.NKK).map(u => u.id)
        });
      },
      async updateProjectZkks(selectedItems) {
        await this.$store.dispatch('setProjectZkks', {
          projectId: this.projectId,
          userIds  : selectedItems.filter(u => u.role === UserRole.ZKK).map(u => u.id)
        });
      },
      async updateProjectHierarchy(selectedItems) {
        await this.$store.dispatch('setProjectHierarchy', {
          projectId: this.projectId,
          childProjectIds  : selectedItems.map(p => p.project_id)
        });
      },
      async enableOrDisableAutomaticSiteLogs(enabled) {
        await this.$store.dispatch('setAutomaticSiteLogs', {
          projectId: this.projectId,
          enabled: enabled
        })
      }
    }
  }
</script>

<template>
  <div>
    <v-card class="md-12">
      <v-toolbar dense>
        <v-toolbar-title>Moj profil</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-btn :loading="passwordResetLoading" color="primary" @click="sendPasswordReset()">Nastavi geslo</v-btn>
      </v-card-text>
    </v-card>

    <v-dialog
        width="500"
        v-model="showPasswordResetSentDialog"
    >
      <v-card>
        <v-card-text>
          <p>
            <b>Povezava za nastavitev gesla je poslana!</b>
          </p>
          <p>
            Na vaš email naslov <b>{{userEmail}} smo poslali povezavo</b> preko katere si lahko <b>nastavite geslo</b>.
          </p>
          <p>
            V kolikor ne želite spremeniti gesla, lahko sporočilo preprosto ignorirate in uporabljate sedanje geslo.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showPasswordResetSentDialog=false">Zapri</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';

export default {
  name: "MeView",
  data: () => {
    return {
      passwordResetLoading: false,
      showPasswordResetSentDialog: false
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.currentUser.email;
    }
  },
  methods: {
    async sendPasswordReset() {
      this.passwordResetLoading = true;
      await firebase
          .auth()
          .sendPasswordResetEmail(
              this.userEmail,
              {
                url: window.location.origin + '/login',
                handleCodeInApp: false
              }
          )
      ;
      this.passwordResetLoading = false;
      this.showPasswordResetSentDialog = true;
    }
  }
}
</script>

<style scoped>

</style>

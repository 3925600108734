<template>
  <v-select
    v-model="currentCompanyId"
    :items="companies"
    @change="switchCompany"
  />
</template>

<script>
  import router from '../../router';

  export default {
    name: 'CompanySwitch',
    data() {
      return {
        currentCompanyId: this.$store.getters.currentCompany.company_id,
        companies: this.$store.getters.companies.map(c => {
            return {
              text:  c.name,
              value: c.company_id
            };
        })
      };
    },
    methods: {
      async switchCompany(companyId) {
        await this.$store.dispatch('switchCompany', companyId);
        router.push('/');
      }
    }
  }
</script>

<style scoped>

</style>

import Vue from 'vue';
import {UserRole} from '@/model/user/UserRole';

Vue.filter('user_role', (value) => {
  switch (value) {
    case 'admin':
      return 'administrator';
    case 'project_lead':
      return 'vodja projektov';
    case 'reviewer':
      return 'pregledovalec';
    case UserRole.SUBCONTRACTOR:
      return 'podizvajalec;'
    case UserRole.SUPERVISOR:
      return 'nadzornik';
    case UserRole.NKK:
      return 'Notranja kontrola kakovosti';
    case UserRole.ZKK:
      return 'Zunanja kontrola kakovosti';
    default:
      return 'neznana';
  }
});

Vue.filter('ucfirst', (value) => {
  if (typeof value !== 'string') {
    return ''
  }

  return value.charAt(0).toUpperCase() + value.slice(1)
});

<template>
  <v-dialog
    v-model="isOpen"
    max-width="600px"
  >
    <v-btn
      slot="activator"
    >
      Pošlji v podpis
    </v-btn>

    <v-card>
      <v-card-title>
        <span class="headline">
          Pošlji v podpis
        </span>
      </v-card-title>

      <v-card-text v-show="nooneToSend">
        <div>
          Na projektu ni dodeljenih nadzornikov oz. notranje/zunanje kontrole kakovosti. To lahko storite na strani z
          <router-link
            :to="{ name: 'Uporabniški dostopi', params: { projectId: projectId } }"
          >
            uporabniškimi dostopi
          </router-link>.
        </div>
      </v-card-text>

      <v-card-text v-show="supervisors.length">
        <v-list
            subheader
        >
          <v-subheader>Dnevni list bo v podpis poslan nadzornikom</v-subheader>
          <v-list-tile
              v-for="user in supervisors"
              :key="user.id"
          >
            <v-list-tile-action>
              <v-checkbox v-model="selectedSupervisors"
                          :value="user.id"
              ></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ user.first_name }} {{ user.last_name }} &lt;{{ user.email }}&gt;</v-list-tile-title>
              <v-list-tile-sub-title>Nadzornik</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>

      <v-card-text v-show="qualityAssurance.length">
        <v-list
            subheader
        >
          <v-subheader>Notranja in zunanja kontrola kakovosti</v-subheader>
          <v-list-tile
              v-for="user in qualityAssurance"
              :key="user.id"
          >
            <v-list-tile-action>
              <v-checkbox v-model="selectedQualityAssurance" :value="user.id"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>{{ user.first_name }} {{ user.last_name }} &lt;{{ user.email }}&gt;</v-list-tile-title>
              <v-list-tile-sub-title>{{ user.role }}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          flat
          @click="isOpen = false"
        >
          Zapri
        </v-btn>
        <v-btn
          color="primary"
          :loading="sending"
          :disabled="nooneToSend"
          @click="sendToSign()"
        >
          Pošlji v podpis
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SendJournalToSignature",
  props: ['projectId', 'date', 'sequence', 'supervisors', 'nkks', 'zkks', 'isSigned'],
  data() {
    return {
      isOpen: false,
      sending: false,
      selectedSupervisors: [],
      selectedQualityAssurance: []
    }
  },
  computed: {
    nooneToSend() {
      return this.supervisors.length < 1 && this.zkks.length < 1 && this.nkks.length < 1;
    },
    qualityAssurance() {
      return [
          ...(this.nkks).map(u => { u.role = 'Notranja kontrola kakovosti'; return u; }),
          ...this.zkks.map(u => { u.role = 'Zunanja kontrola kakovosti'; return u; }),
      ];
    },
    selectedSupervisorUsers() {
      return this.supervisors.filter(u => this.selectedSupervisors.includes(u.id));
    },
    selectedQualityAssuranceUsers() {
      return this.qualityAssurance.filter(u => this.selectedQualityAssurance.includes(u.id));
    }
  },
  methods: {
    async sendToSign() {
      this.sending = true;

      const dates = Array.isArray(this.date) ? this.date : [this.date];
      const sequences = Array.isArray(this.sequence) ? this.sequence : [this.sequence];

      for (let i=0; i<dates.length; i++) {
        await this.$store.dispatch('sendJurnalToSignature', {
          projectId: this.projectId,
          date: dates[i],
          sequence: _.padStart(parseInt(sequences[i]) - 1, 3, '0'),
          supervisors: this.selectedSupervisorUsers,
          qualityAssurance: this.selectedQualityAssuranceUsers,
        });
      }

      this.selectedSupervisors = [];
      this.selectedQualityAssurance = [];
      this.isOpen = false;
      this.sending = false;
    },
  }
}
</script>

<style scoped>

</style>

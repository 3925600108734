import store from "../store/store";

const FullStory = {
  install(Vue, options) {
    let router         = options.router;
    let identifyCalled = false;

    Vue.mixin({
      mounted() {

        router.afterEach(() => {
          if (!identifyCalled && window.FS) {
            const currentUser = this.$store.getters.currentUser;

            if (currentUser.id) {
              FS.identify(currentUser.id, {
                email: currentUser.email
              });

              identifyCalled = true;
            }
          }
        });
      }
    });
  }
};

export default FullStory;

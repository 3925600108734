
export default {
  API_PDF_EXPORT_BASE       : process.env.VUE_APP_API_PDF_EXPORT_BASE,
  API_SIGN_SITELOG          : process.env.VUE_APP_API_SIGN_SITELOG,
  FIREBASE_APIKEY           : process.env.VUE_APP_FIREBASE_APIKEY,
  FIREBASE_AUTHDOMAIN       : process.env.VUE_APP_FIREASE_AUTHDOMAIN,
  FIREBASE_DATABASEURL      : process.env.VUE_APP_FIREBASE_DATABASEURL,
  FIREBASE_PROJECTID        : process.env.VUE_APP_FIREBASE_PROJECTID,
  FIREBASE_STORAGEBUCKET    : process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  FIREBASE_MESSAGINGSENDERID: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  FIREBASE_APPID            : process.env.VUE_APP_FIREBASE_APPID,

  // Vue.js specific configs
  PERFORMANCE: process.env.VUE_APP_PERFORMANCE || false,
}

<template>
  <div v-if="allow">
    <v-dialog
        v-model="isOpen"
        persistent
        max-width="600px"
    >
      <template v-slot:activator="{ on }">
        <v-btn
            color="primary"
            dark
            v-on="on"
        >
          Dodaj vpis
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">
            {{title}}
          </span>
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="note"
            :label="description"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="blue darken-1"
              flat
              @click="isOpen = false"
          >
            Zapri
          </v-btn>
          <v-btn
              id="create_new_project"
              color="blue darken-1"
              flat
              :loading="saving"
              @click="saveNote()"
          >
            Shrani
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'AddAdditionalNote',
  props: ['title', 'description', 'section', 'projectId', 'date', 'allow'],
  data() {
    return {
      isOpen: false,
      saving: false,
      note: '',
    };
  },
  methods: {
    async saveNote() {
      await this.$store.dispatch('addSiteLogAdditionalNote', {
        projectId: this.projectId,
        date: this.date,
        section: this.section,
        note: this.note
      });

      this.note = '';
      this.saving = false;
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>

</style>

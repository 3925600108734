<template>
  <v-app id="inspire">
    <v-toolbar
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        color="primary"
        dark
        app
        fixed
        class="gradient"
    >
      <div style="width: 100%;text-align: center;line-height: 1;">
        <div>
          <span style="font-size: x-large;">
            Site<b>LOG</b>
          </span>
        </div>
        <div>
          <span style="font-size: x-small;">
            We value your time.
          </span>
        </div>
      </div>
    </v-toolbar>
    <v-content>
      <v-container fluid>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step step="1">Pregled dokumenta</v-stepper-step>
            <v-stepper-step step="2">Podpis</v-stepper-step>
            <v-stepper-step step="3">Dodajanje opomb</v-stepper-step>
            <v-stepper-step step="4">Zaključek</v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-- Pregled dokumenta -->
            <v-stepper-content step="1">
              <v-btn
                  color="primary"
                  @click="step = 2"
              >
                Naprej
              </v-btn>

              <PdfPreview v-bind:document-url="documentUrl"></PdfPreview>
            </v-stepper-content>

            <!-- Podpis -->
            <v-stepper-content step="2">
              <div v-show="showSignaturePad">
                Lastoročen podpis vnesite v črn okvirček:
                <br>
                <canvas
                    id="signature-nadzorni"
                    width="400"
                    height="200"
                    style="border: 1px solid #000000;"
                />
              </div>

              <div v-show="!showSignaturePad">
                <img :src="signature">
              </div>

              <v-btn
                v-show="!showSignaturePad"
                color="primary"
                flat
                @click="showSignaturePad=true"
              >
                Ustvari nov podpis
              </v-btn>
              <v-btn
                  color="primary"
                  @click="step = 3"
              >
                Podpiši
              </v-btn>
            </v-stepper-content>

            <!-- Vnos opomb s strani nadzora -->
            <v-stepper-content step="3">
              <h3>6. Dodatne ugotovitve, navodila, sporočila in pripombe ter vpisi in vrisi (sporočila izvajalcu)</h3>
              <div class="previous-note-entries">
                {{siteLog.dopolnilni_del}}
              </div>
              <v-textarea v-model="notes.dopolnilni_del"></v-textarea>

              <h3>6.a Opombe in zahteve odgovornega vodje projekta za pridobitev gradbenega dovoljenja</h3>
              <div class="previous-note-entries">
                {{siteLog.opombe_in_zahteve_odgovornega_vodje}}
              </div>
              <v-textarea v-model="notes.opombe_in_zahteve_odgovornega_vodje"></v-textarea>

              <h3>6.b Opombe in zahteve inšpekcijskih služb</h3>
              <div class="previous-note-entries">
                {{siteLog.opombe_in_zahteve_inspekcijskih_sluzb}}
              </div>
              <v-textarea v-model="notes.opombe_in_zahteve_inspekcijskih_sluzb"></v-textarea>

              <h3>6.c Opombe in zahteve odgovornih projektantov ter strokovnjakov z drugih področij</h3>
              <div class="previous-note-entries">
                {{siteLog.opombe_in_zahteve_projektantov}}
              </div>
              <v-textarea v-model="notes.opombe_in_zahteve_projektantov"></v-textarea>

              <h3>6.d Opombe in zahteve soglasodajalcev</h3>
              <div class="previous-note-entries">
                {{siteLog.opombe_in_zahteve_soglasodajalcev}}
              </div>
              <v-textarea v-model="notes.opombe_in_zahteve_soglasodajalcev"></v-textarea>

              <v-btn
                  color="primary"
                  @click="previewAtFinalStep()"
                  :loading="saving"
              >
                Naprej
              </v-btn>
            </v-stepper-content>

            <!-- Zaključek/pregled -->
            <v-stepper-content step="4">
              <v-alert
                  :value="true"
                  type="success"
              >
                Podpisovanje je končano, lahko zaprete okno brskalnika.
              </v-alert>

              <div>
                <v-btn color="primary" @click="downloadSiteLog()">Prenos dnevnega lista</v-btn>
              </div>

              <PdfPreview v-bind:document-url="documentUrl" v-if="step == 4"></PdfPreview>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import SignaturePad from 'signature_pad';
  import trimCanvas from 'trim-canvas';
  import PdfPreview from '@/views/components/PdfPreview';
  import axios from 'axios';

  export default {
    components: { PdfPreview },
    data() {
      return {
        step: 1,
        projectId: null,
        siteLogId: null,
        secret: null,
        siteLog: {
          dopolnilni_del: '',
          opombe_in_zahteve_odgovornega_vodje: '',
          opombe_in_zahteve_inspekcijskih_sluzb: '',
          opombe_in_zahteve_projektantov: '',
          opombe_in_zahteve_soglasodajalcev: '',
        },
        signature: null,
        showSignaturePad: false,
        notes: {
          dopolnilni_del: '',
          opombe_in_zahteve_odgovornega_vodje: '',
          opombe_in_zahteve_inspekcijskih_sluzb: '',
          opombe_in_zahteve_projektantov: '',
          opombe_in_zahteve_soglasodajalcev: '',
          signature_odgovorni: null,
        },
        nadzorniSignaturePad: null,
        documentUrl: null,
        saving: false,

        // Final step data
        currentPage: 1,
        numOfPages: null,
        pdfRef: null
      }
    },
    async created() {
      const requestData = JSON.parse(atob(this.$route.query.d));
      this.projectId = requestData.project_id;
      this.siteLogId = requestData.site_log_id;
      this.secret = requestData.key;
      this.documentUrl = `${this.$config.API_PDF_EXPORT_BASE}/dnevni-list/${this.projectId}/${requestData.date},${requestData.sequence}`;

      const siteLogResponse = (await axios.get(`${this.$config.API_SIGN_SITELOG}?siteLogId=${this.siteLogId}`, {
        headers: {
          Authorization: this.secret
        }
      })).data;

      this.siteLog = siteLogResponse.sitelog;
      this.signature = siteLogResponse.signature;

      this.notes = {
        dopolnilni_del: '',
        opombe_in_zahteve_odgovornega_vodje: '',
        opombe_in_zahteve_inspekcijskih_sluzb: '',
        opombe_in_zahteve_projektantov: '',
        opombe_in_zahteve_soglasodajalcev: '',
      };

      this.showSignaturePad = !(this.signature || '').startsWith('data:image');
    },
    async mounted() {
      this.initSignaturePad();
    },
    methods: {
      async initSignaturePad() {
        const signatureCanvas = document.getElementById('signature-nadzorni');
        this.nadzorniSignaturePad = new SignaturePad(signatureCanvas, {
          backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
        });

        signatureCanvas.width = signatureCanvas.parentNode.parentNode.parentNode.parentElement.clientWidth - 64;
        signatureCanvas.height = signatureCanvas.parentNode.parentNode.parentNode.parentElement.clientHeight - 64;
      },
      async saveAndSign() {
        trimCanvas(this.nadzorniSignaturePad.canvas);
        this.notes.signature_odgovorni = this.nadzorniSignaturePad.isEmpty()
          ? this.signature
          : this.nadzorniSignaturePad.toDataURL('image/png')
        ;

        this.saving = true;

        const requestBody = {
          signature: this.notes.signature_odgovorni,
          dopolnilni_del: this.notes.dopolnilni_del,
          opombe_in_zahteve_odgovornega_vodje: this.notes.opombe_in_zahteve_odgovornega_vodje,
          opombe_in_zahteve_inspekcijskih_sluzb: this.notes.opombe_in_zahteve_inspekcijskih_sluzb,
          opombe_in_zahteve_projektantov: this.notes.opombe_in_zahteve_projektantov,
          opombe_in_zahteve_soglasodajalcev: this.notes.opombe_in_zahteve_soglasodajalcev,
        }

        await axios.post(
            `${this.$config.API_SIGN_SITELOG}?siteLogId=${this.siteLogId}`,
            requestBody,
            {
              headers: {
                Authorization: this.secret
              }
            }
        );

        this.saving = false;
      },
      /**
       * Manipulating PDF on last review step
       */
      async previewAtFinalStep() {
        await this.saveAndSign();
        this.step = 4;
      },
      downloadSiteLog() {
        window.open(this.documentUrl, '_blank');
      },
    }
  }
</script>


<style scoped>
  .previous-note-entries {
    white-space: pre;
  }
</style>

<template>
  <v-form ref="imageUploadForm">
    <image-uploader
        ref="image"
        prepend-icon="image"
        :debug="0"
        :max-width="2048"
        :quality="0.9"
        :auto-rotate="true"
        :preview="true"
        accept="image/*"
        do-not-resize="['gif', 'svg']"
        required
        :rules="imageRules"
        label="Slika"
        output-format="blob"
        @input="setImage"
    />
    <v-text-field
        v-model="imageDescription"
        label="Opis slike"
    />

    <v-btn
        color="info"
        :loading="uploadInProgress"
        @click="uploadImage"
    >
      Naloži sliko
    </v-btn>
  </v-form>
</template>

<script>
export default {
  name: 'JournalImageUploader',
  props: ['projectId', 'date'],
  data() {
    return {
      uploadInProgress: false,
      image: null,
      imageName: null,
      imageRules: [
        v => !!v || 'Slika je obvezna',
      ],
      imageDescription: null,
      newImageDatePickerOpen: false,
    }
  },
  methods: {
    setImage(image) {
      this.image     = image;
      this.imageName = document.getElementById('fileInput').files[0].name;
    },
    async uploadImage() {
      if (this.$refs.imageUploadForm.validate()) {
        this.uploadInProgress = true;

        const projectId   = this.projectId;
        const image       = this.image;
        const imageName   = this.imageName;
        const imageDate   = this.date;
        const description = this.imageDescription;

        await this.$store.dispatch('uploadSiteLogImage', {
          projectId,
          image,
          imageName,
          imageDate,
          description
        });

        this.uploadInProgress = false;
        this.imageDescription = '';
      }
    },
  }
}
</script>

<style scoped>

</style>

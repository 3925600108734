export default function (user, project) {
  if ('admin' === user.role) {
    return true;
  }

  if ((project.project_leads || []).includes(user.id)) {
    return true;
  }

  if ((project.project_subcontractors || []).includes(user.id)) {
    return true;
  }

  if ((project.project_reviewers || []).includes(user.id)) {
    return true;
  }

  if ((project.project_supervisors || []).includes(user.id)) {
    return true;
  }

  if ((project.project_nkks || []).includes(user.id)) {
    return true;
  }

  if ((project.project_zkks || []).includes(user.id)) {
    return true;
  }

  return false;
}

<template>
  <v-app id="inspire">
    <v-content>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="sendForgottenEmail"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  dark
                  color="primary"
                >
                  <v-toolbar-title>Pozabljeno geslo</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <div v-if="success">
                    Email s povezavo za ponastavitev gesla je bil poslan na {{ email }}.
                  </div>
                  <v-text-field
                    v-if="!success"
                    v-model="email"
                    prepend-icon="email"
                    name="email"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                    required
                  />
                </v-card-text>
                <v-card-actions v-if="!success">
                  <v-spacer />
                  <v-btn
                    data-cy="login"
                    color="primary"
                    :disabled="!valid"
                    type="submit"
                  >
                    Ponastavi geslo
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
                />
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import firebase from 'firebase';

  export default {
    data: () => ({
      loading: false,
      valid: false,
      email: '',
      lastErrorCode: '',
      success: false,
    }),
    computed: {
      emailRules() {
        return [
          v => !!v || 'Vnos e-pošte je obvezen',
          v => /.+@.+/.test(v) || 'Vpišite veljaven e-poštni naslov',
          v => this.lastErrorCode !== 'auth/user-not-found' || 'Uporabnik ne obstaja'
        ]
      },
    },
    mounted() {
    },
    methods: {
      async sendForgottenEmail() {
        this.loading = true;

        if (this.$refs.form.validate()) {
          try {
            await firebase
                .auth()
                .sendPasswordResetEmail(
                    this.email,
                    {
                      url: window.location.origin + '/login',
                      handleCodeInApp: false
                    }
                );
            this.success = true;
          } catch(e) {
            this.lastErrorCode = e.code;
            this.$refs.form.validate();
            this.lastErrorCode = '';
          }
        }

        this.loading = false;
      }
    }
  }
</script>

import Vue from 'vue';
import Vuex from 'vuex';
import firebase from 'firebase';
import VuexPersistence from 'vuex-persist';
import UUID from 'uuid';
import _set from 'lodash/set';
import _setWith from 'lodash/setWith';
import _orderBy from 'lodash/orderBy';
import crypto from 'crypto';
import VERSION from '../../version';
import {UserRole} from '@/model/user/UserRole';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => {
    return {
      version: state.version,
      currentUser: state.currentUser,
      currentUserRoles: state.currentUserRoles,
      currentCompany: state.currentCompany,
      companies: state.companies,
      uvodniListi: [],
      siteLogs: [],
      listOfSiteLogs: [],
      siteLogImages: [],
      users: [],
    }
  }
});

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  plugins: [vuexLocal.plugin],
  state: {
    version: VERSION,
    currentUser: {
      id: null,
      email: '',
      is_authenticated: false,
      companies: []
    },
    currentUserRoles: [],
    currentCompany: {
      id: null,
      name: ''
    },
    // List of companies user has access to
    companies: [],
    listOfProjects: [
      /** Example:
       * {
       *   id  : 1,
       *   name: ''
       * }
       */
    ],
    uvodniListi: [],
    siteLogs: [],
    listOfSiteLogs: [
      /**
       * Example:
       * {
       *   project_id: 'uuid',
       *   sequence  : 1,
       *   date      : '2019-02-05'
       * }
       */
    ],
    siteLogImages: [
      /**
       * Example:
       * {
       *   project_id: projectId,
       *   image_id: imageId,
       *   date: imageDate,
       *   original_name: image.name,
       *   description: description,
       *   download_url: downloadUrl,
       *   thumbs: {
       *     // Let's make full image as thumbnail for now (as thumbnails are generated in background)
       *     256: [downloadUrl],
       *     1024: [downloadUrl]
       *   }
       * }
       */
    ],
    users: [
      /**
       * All users in account.
       *
       *
       * Example:
       *  {
       *    id: null,
       *    email: '',
       *    first_name: '',
       *    last_name: '',
       *    role: 'admin' | ...
       *  }
       */
    ]
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    signature(state) {
      if (state.currentUser.profile && state.currentUser.profile.signature) {
        return state.currentUser.profile.signature;
      }

      return null;
    },
    currentCompany(state) {
      return state.currentCompany;
    },
    companies(state) {
      return state.companies;
    },

    // ------------------------------------------------------
    //                   Project management
    // ------------------------------------------------------
    projectSummary: (state) => (projectId) => {
      return state
        .listOfProjects
        .find(p => p.project_id == projectId)
      ;
    },
    listOfProjects: (state) => () => {
      return state
        .listOfProjects
        .map(p => {
          // Making sure all properties are set
          p.project_leads = p.project_leads || [];
          p.project_subcontractors = p.project_subcontractors || [];
          p.project_reviewers = p.project_reviewers || [];
          p.project_supervisors = p.project_supervisors || [];
          p.project_nkks = p.project_nkks || [];
          p.project_zkks = p.project_zkks || [];
          p.subprojects = p.subprojects || [];

          return p;
      });
    },
    possibleProjectLeads: (state, getters) => (projectId) => {
      return state
        .users
        .filter(u => u.role === 'project_lead')
      ;
    },
    projectLeads: (state, getters) => (projectId) => {
      const projectLeads = getters.projectSummary(projectId).project_leads || [];

      return state
        .users
        .filter(u => projectLeads.includes(u.id) || u.role === 'admin')
      ;
    },
    possibleProjectSubcontractors: (state) => (projectId) => {
      return state
        .users
        .filter(u => u.role === UserRole.SUBCONTRACTOR)
        ;
    },
    possibleProjectReviewers: (state) => (projectId) => {
      return state
        .users
        .filter(u => u.role === 'reviewer')
      ;
    },
    projectSubcontractors: (state, getters) => (projectId) => {
      const projectSubcontractors = getters.projectSummary(projectId).project_subcontractors || [];

      return state
        .users
        .filter(u => projectSubcontractors.includes(u.id))
      ;
    },
    projectReviewers: (state, getters) => (projectId) => {
      const projectReviewers = getters.projectSummary(projectId).project_reviewers || [];

      return state
        .users
        .filter(u => projectReviewers.includes(u.id))
      ;
    },
    possibleProjectSupervisors: (state) => (projectId) => {
      return state
        .users
        .filter(u => u.role === UserRole.SUPERVISOR)
      ;
    },
    possibleProjectNkks: (state) => (projectId) => {
      return state
        .users
        .filter(u => u.role === UserRole.NKK)
      ;
    },
    possibleProjectZkks: (state) => (projectId) => {
      return state
        .users
        .filter(u => u.role === UserRole.ZKK)
      ;
    },
    projectSupervisors: (state, getters) => (projectId) => {
      const projectSupervisors = getters.projectSummary(projectId).project_supervisors || [];

      return state
        .users
        .filter(u => projectSupervisors.includes(u.id))
      ;
    },
    projectNkks: (state, getters) => (projectId) => {
      const projectNkk = getters.projectSummary(projectId).project_nkks || [];

      return state
        .users
        .filter(u => projectNkk.includes(u.id))
      ;
    },
    projectZkks: (state, getters) => (projectId) => {
      const projectZkk = getters.projectSummary(projectId).project_zkks || [];

      return state
        .users
        .filter(u => projectZkk.includes(u.id))
      ;
    },
    uvodniList: (state) => (projectId) => {
      return state.uvodniListi.find(p => p.project_id == projectId);
    },
    uvodniListSection: (state) => (projectId, sectionNumber) => {
      return state
        .uvodniListi
        .find(p => p.project_id == projectId)
        .sections[sectionNumber]
    },
    seznamDnevnihListov: (state) => (projectId) => {
      return _orderBy(
        state.listOfSiteLogs.filter(l => l.project_id == projectId),
        ['date'],
        ['asc']
      );
    },
    dnevniList: (state) => (projectId, date) => {
      return state.siteLogs.find(d => d.project_id == projectId && d.date == date);
    },
    siteLogImages: (state) => (projectId) => {
      return state
        .siteLogImages
        .filter((i) => i && i.project_id === projectId && 'undefined' !== typeof i.date)
        ;
    },
    previousSiteLogDate: (state) => (projectId, beforeDate) => {
      const previousSiteLog = _orderBy(
        state.listOfSiteLogs.filter(l => l.project_id === projectId && l.date < beforeDate),
        ['date'],
        ['desc']
      );

      return previousSiteLog.length > 0 ? previousSiteLog[0].date : null;
    },
    nextSiteLogDate: (state) => (projectId, afterDate) => {
      const nextSiteLog = _orderBy(
        state.listOfSiteLogs.filter(l => l.project_id === projectId && l.date > afterDate),
        ['date'],
        ['asc']
      );

      return nextSiteLog.length > 0 ? nextSiteLog[0].date : null;
    },
  },
  mutations: {
    // ------------------------------------------------------
    //                     User management
    // ------------------------------------------------------
    userLoggedIn(state, info) {
      state.currentUser          = info.user;
      state.currentUser.role     = info.roles.find(r => r.company_id === info.company.company_id).role_type;
      state.currentUserRoles     = info.roles;
      state.currentCompany       = info.company;
      state.companies            = info.companies;
    },
    usersListUpdated(state, users) {
      state.users = users;
    },
    userRoleChanged(state, { userId, roleType }) {
      const user = state.users.find(u => u.id === userId);
      user.role  = roleType;
    },
    userRemovedFromCompany(state, { userId }) {
      state.users = state.users.filter(u => u.id !== userId);
    },
    signatureSaved(state, signature) {
      if (!state.currentUser.profile) {
        state.currentUser.profile = {};
      }

      state.currentUser.profile.signature = signature;
    },
    companySwitched(state, { companyId }) {
      state.currentCompany   = state.companies.find(c => c.company_id === companyId);
      state.currentUser.role = state.currentUserRoles.find(r => r.company_id === companyId).role_type;
      state.projects       = [];
      state.listOfProjects = [];
      state.users          = [];
    },

    // ------------------------------------------------------
    //                    Gradbeni dnevnik
    // ------------------------------------------------------
    projectCreated(state, project) {
      // Clear existing records if already exists
      state.listOfProjects = state.listOfProjects.filter(p => p.project_id != project.project_id);
      state.uvodniListi    = state.uvodniListi.filter(p => p.project_id != project.project_id);

      if ('undefined' === typeof project.uvodni_list.sections[11]) {
        // Upgrade if document without section 11 loaded/created
        project.uvodni_list.sections[11] = {
          section_number: 11,
          datum: '',
          podpisi: ''
        };
      }

      state.listOfProjects.push({
        project_id: project.project_id,
        name: project.name,
        project_leads: project.project_leads || [],
        project_subcontractors : project.project_subcontractors  || [],
        project_reviewers: project.project_reviewers || [],
        project_supervisors: project.project_supervisors || [],
        project_nkks: project.project_nkks || [],
        project_zkks: project.project_zkks || [],
        subprojects: project.subprojects || [],
        archived: !!project.archived,
        automatic_site_logs_enabled: !!project.automatic_site_logs_enabled,
      });
      state.uvodniListi.push({
        project_id: project.project_id,
        sections  : project.uvodni_list.sections
      });
    },
    projectNameChanged(state, { projectId, name }) {
      state.listOfProjects.find(p => p.project_id === projectId).name = name;
    },
    projectDeleted(state, projectId) {
      state.listOfProjects = state.listOfProjects.filter(p => p.project_id != projectId);
      state.uvodniListi    = state.uvodniListi.filter(p => p.project_id != projectId);
    },
    projectLeadsChanged(state, { projectId, userIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .project_leads = userIds
      ;
    },
    projectArchived(state, projectId) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .archived = true
      ;
    },
    projectUnarchived(state, projectId) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .archived = false
      ;
    },
    automaticSiteLogsChanged(state, { projectId, enabled }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .automatic_site_logs_enabled = enabled
      ;
    },
    projectSubcontractorsChanged(state, { projectId, userIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .project_subcontractors = userIds
      ;
    },
    projectReviewersChanged(state, { projectId, userIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .project_reviewers = userIds
      ;
    },
    projectSupervisorsChanged(state, { projectId, userIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .project_supervisors = userIds
      ;
    },
    projectNkksChanged(state, { projectId, userIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .project_nkks = userIds
      ;
    },
    projectZkksChanged(state, { projectId, userIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .project_zkks = userIds
      ;
    },
    projectHierarchyChanged(state, { projectId, childProjectIds }) {
      state
        .listOfProjects
        .find(p => p.project_id === projectId)
        .subprojects = childProjectIds
      ;
    },
    introductoryPageSectionUpdated(state, { projectId, sectionNumber, fieldPath, value }) {
      state
        .uvodniListi
        .find(l => l.project_id == projectId)
        .sections[sectionNumber][fieldPath] = value;
      ;
    },
    siteLogCreated(state, siteLog) {
      state.siteLogs       = state.siteLogs.filter(l => !(l.date === siteLog.date && l.project_id === siteLog.project_id));
      state.listOfSiteLogs = state.listOfSiteLogs.filter(l => !(l.date === siteLog.date && l.project_id === siteLog.project_id));

      state.siteLogs.push({
        ...siteLog,
        signature_logs: []
      });
      state.listOfSiteLogs.push({
        project_id: siteLog.project_id,
        sequence  : state.sequence,
        date      : siteLog.date,
        locked_for_edit: !!siteLog.locked_for_edit,
        signed_by: {
          nadzornik: !!siteLog.locked_for_edit,
          sestavljalec: !!siteLog.signature_sestavil,
          odgovorni: !!siteLog.signature_nadzorni,
        }
      });
    },
    projectSiteLogsFetched( state , { projectId, fetchedSiteLogs }) {
      state.siteLogs = state.siteLogs.filter(l => l.project_id !== projectId);
      state.listOfSiteLogs = state.listOfSiteLogs.filter(l => l.project_id !== projectId);

      fetchedSiteLogs.forEach(siteLog => {
        state.siteLogs.push({
          ...siteLog,
          signature_logs: []
        });
        state.listOfSiteLogs.push({
          project_id: siteLog.project_id,
          sequence  : state.sequence,
          date      : siteLog.date,
          locked_for_edit: !!siteLog.locked_for_edit,
          signed_by: {
            nadzornik: !!siteLog.locked_for_edit,
            sestavljalec: !!siteLog.signature_sestavil,
            odgovorni: !!siteLog.signature_nadzorni,
          }
        });
      });
    },
    siteLogDeleted(state, { projectId, date }) {
      state.siteLogs = state
        .siteLogs
        .filter(s => !(s.project_id === projectId && s.date === date))
      ;

      state.listOfSiteLogs = state
        .listOfSiteLogs
        .filter(s => !(s.project_id === projectId && s.date === date))
      ;
    },
    siteLogFieldUpdated(state, { projectId, date, fieldPath, value }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);

      _set(siteLog, fieldPath, value);
    },
    machineAddedToSiteLog(state, { projectId, date, id, type, description, count }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);
      const machine = siteLog.stroji[type].find(m => m.id == id);

      if (machine) {
        machine.description = description;
        machine.count       = count;
      } else {
        siteLog.stroji[type].push({id: id, description: description, count: count})
      }
    },
    machineRemovedFromSiteLog(state, { projectId, date, id, type }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);

      siteLog.stroji[type] = siteLog.stroji[type].filter(m => m.id != id);
    },
    machineDescriptionUpdated(state, { projectId, date, id, type, description }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);
      const machine = siteLog.stroji[type].find(m => m.id == id);

      if (machine) {
        machine.description = description;
      }
    },
    machineCountUpdated(state, { projectId, date, id, type, count }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);
      const machine = siteLog.stroji[type].find(m => m.id == id);

      if (machine) {
        machine.count = count;
      }
    },
    siteLogFileAttached(state, { projectId, date, attachment }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);

      siteLog.attachments = siteLog.attachments.filter(a => a && a.attachment_id !== attachment.attachment_id);
      siteLog.attachments.push(attachment);
    },
    siteLogFileDeleted(state, { projectId, date, attachmentId }) {
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);

      siteLog.attachments = siteLog.attachments.filter(a => a && a.attachment_id !== attachmentId);
    },
    siteLogImageCreated(state, image) {
      state.siteLogImages = state.siteLogImages.filter(i => i && i.image_id !== image.image_id);
      state.siteLogImages.push(image);
    },
    siteLogImageUpdated(state, updatedImageData) {
      const image = state.siteLogImages.find(i => i && i.image_id === updatedImageData.image_id);

      if (image) {
        image.date        = updatedImageData.date;
        image.description = updatedImageData.description;
      }
    },
    siteLogImageDeleted(state, imageId) {
      state.siteLogImages = state.siteLogImages.filter(i => i && i.image_id !== imageId);
    },
    signatureLogCreated(state, event) {
      const siteLog = state.siteLogs.find(l => l.project_id === event.project_id && l.date === event.date);

      siteLog.signature_logs = (siteLog.signature_logs ? siteLog.signature_logs : [])
        .filter(l => l.id !== event.id)
      ;
      siteLog.signature_logs.push(event);
    }
  },
  actions: {
    // ------------------------------------------------------
    //                     User management
    // ------------------------------------------------------

    /**
     * Logs in user with the default company
     *
     * @param commit
     * @param {string} email
     * @param {string} password
     * @param {boolean} emailLogin
     * @param {string} windowLocationHref
     * @param {string} companyId
     * @returns {Promise<void>}
     */
    async userlogin({ commit }, { email, password, emailLogin, windowLocationHref, companyId }) {
      let credentials;

      email = email.toLowerCase();

      if (emailLogin) {
        credentials = await firebase
          .auth()
          .signInWithEmailLink(email, windowLocationHref)
        ;
      } else {
        credentials = await firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
        ;
      }

      const db        = firebase.firestore();
      const userRoles = (await db
        .collection('user_roles')
        .where('user_id', '==', credentials.user.uid)
        .get())
        .docs
        .map(d => d.data())
      ;

      let defaultCompany = false;

      const companyIdsUserHasAccessTo = new Set( userRoles.map(r => r.company_id) );
      const companiesUserHasAccessTo  = (await db
        .collection('companies')
        .where('company_id', 'in', Array.from(companyIdsUserHasAccessTo))
        .get())
        .docs
        .map(d => d.data())
      ;

      if (userRoles.length > 0) {
        defaultCompany =
          companiesUserHasAccessTo.find(c => c.company_id === companyId) ||
          companiesUserHasAccessTo.find(c => c.company_id === userRoles[0].company_id)
        ;
      }

      const userProfile = (await db
        .collection('user_profiles')
        .doc(credentials.user.uid)
        .get())
        .data()
      ;

      commit('userLoggedIn', {
        user: {
          id:    credentials.user.uid,
          email: credentials.user.email.toLowerCase(),
          is_authenticated: true,
          profile: userProfile,
        },
        roles  : userRoles.map(r => { return { role_type: r.role_type, company_id: r.company_id } }),
        company: defaultCompany
          ? { company_id: defaultCompany.company_id, name: defaultCompany.name }
          : false,
        companies: companiesUserHasAccessTo
      });
    },

    async switchCompany({ commit, dispatch }, toCompanyId) {
      commit('companySwitched', {
        companyId: toCompanyId
      });

      dispatch('fetchProjects');
    },

    async migrationTo110Action({ state, commit }) {
      const db        = firebase.firestore();
      const userRoles = (await db
        .collection('user_roles')
        .where('user_id', '==', state.currentUser.id)
        .get())
        .docs
        .map(d => d.data())
      ;

      let defaultCompany = false;

      const companyIdsUserHasAccessTo = new Set( userRoles.map(r => r.company_id) );
      const companiesUserHasAccessTo  = (await db
        .collection('companies')
        .where('company_id', 'in', Array.from(companyIdsUserHasAccessTo))
        .get())
        .docs
        .map(d => d.data())
      ;

      if (userRoles.length > 0) {
        defaultCompany = companiesUserHasAccessTo.find(c => c.company_id === userRoles[0].company_id);
      }

      commit('userLoggedIn', {
        user: state.currentUser,
        roles  : userRoles.map(r => { return { role_type: r.role_type, company_id: r.company_id } }),
        company: defaultCompany
          ? { company_id: defaultCompany.company_id, name: defaultCompany.name }
          : false,
        companies: companiesUserHasAccessTo
      });
    },

    /**
     * Creates:
     *  - new user
     *  - new company
     *  - creates user role
     *
     * @param commit
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @param {string} password
     * @param {string} companyName
     * @returns {Promise<void>}
     */
    async registerNewUser({ commit }, { firstName, lastName, email, password, companyName, phone }) {
      email           = email.toLowerCase();
      const db        = firebase.firestore();
      const companyId = UUID.v1();

      const credentials = await firebase.auth().createUserWithEmailAndPassword(email, password);

      await db.collection('companies').add({
        company_id: companyId,
        name      : companyName
      });

      await db.collection('user_roles').add({
        user_id   : credentials.user.uid,
        company_id: companyId,
        role_type : 'admin'
      });

      await db.collection('user_profiles').doc(credentials.user.uid).set({
        user_id   : credentials.user.uid,
        first_name: firstName,
        last_name : lastName,
        email     : email,
        phone     : phone
      });

      commit('userLoggedIn', {
        user: {
          id:    credentials.user.uid,
          email: credentials.user.email.toLowerCase(),
          is_authenticated: true,
          profile: {
            first_name: firstName,
            last_name : lastName
          }
        },
        roles: [
          { role_type: 'admin', company_id: companyId},
        ],
        company: {
          company_id: companyId,
          name      : companyName
        },
        companies: [
          {
            company_id: companyId,
            name      : companyName
          }
        ]
      });
    },

    async sendEmailLink({ state }, { userId }) {
      const invitee = state.users.find(u => u.id === userId);

      if (!invitee) {
        return Promise.resolve();
      }

      const url = `${window.location.protocol}//${window.location.hostname}/login?email=${invitee.email}`;

      const db = firebase.firestore();
      await db.collection('invites').add({
        user_id   : userId,
        email     : invitee.email,
        company_id: state.currentCompany.company_id,
        inviter_id: state.currentUser.id,
        role_type : invitee.role,
        url       : url,
        skip_sending_invitation_email: false,
        created: (new Date()).toISOString()
      });
    },

    async inviteUser({ commit, state }, { companyId, firstName, lastName, email, roleType }) {
      email = email.toLowerCase();

      const db = firebase.firestore();

      const inviterId       = state.currentUser.id;
      const acceptInviteUrl = `${window.location.protocol}//${window.location.hostname}/login?email=${email}`;

      try {
        const invitedUser = await firebase.auth().createUserWithEmailAndPassword(email, btoa(Math.random()));
        await db.collection('user_roles').add({
          user_id   : invitedUser.user.uid,
          company_id: companyId,
          inviter_id: inviterId,
          role_type : roleType
        });

        await db.collection('user_profiles').doc(invitedUser.user.uid).set({
          user_id   : invitedUser.user.uid,
          first_name: firstName,
          last_name : lastName,
          email     : email
        });

        await db.collection('invites').doc(invitedUser.user.uid).set({
          user_id   : invitedUser.user.uid,
          email     : email,
          company_id: companyId,
          inviter_id: inviterId,
          role_type : roleType,
          url       : acceptInviteUrl,
          skip_sending_invitation_email: false, //[UserRole.SUPERVISOR, UserRole.ZKK, UserRole.NKK].includes(roleType)
        });
      } catch (e) {
        if ('auth/email-already-in-use' === e.code) {
          const existingUserProfile = (await db
            .collection('user_profiles')
            .where('email', '==', email)
            .limit(1)
            .get())
            .docs[0]
            .data()
          ;

          await db.collection('user_roles').add({
            user_id   : existingUserProfile.user_id,
            company_id: companyId,
            inviter_id: inviterId,
            role_type : roleType,
            invited   : true
          });

          const loginUrl = acceptInviteUrl + '&company_id=' + companyId;

          await db.collection('invites').add({
            email     : email,
            company_id: companyId,
            inviter_id: inviterId,
            role_type : roleType,
            url       : loginUrl,
            skip_sending_invitation_email: false,//[UserRole.SUPERVISOR, UserRole.ZKK, UserRole.NKK].includes(roleType)
          });
        }
      }
    },

    /**
     *
     * @return {Promise<User[]>}
     */
    async fetchUsers({ commit, state }) {
      const db        = firebase.firestore();
      const companyId = state.currentCompany.company_id;

      const users = await Promise.all((await db
        .collection('user_roles')
        .where('company_id', '==', companyId)
        .get())
        .docs
        .map(async (roleDoc) => {
          const role = roleDoc.data();
          const user = {
            // Schema
            id: role.user_id,
            email: '',
            first_name: '',
            last_name: '',
            role: role.role_type
          };

          // const userInfo = await firebase.auth().getUser(user.id);
          const userProfile = (await db
            .collection('user_profiles')
            .doc(user.id)
            .get())
            .data()
          ;

          user.email      = userProfile && userProfile.email.toLowerCase() || '?';
          user.first_name = userProfile && userProfile.first_name || '';
          user.last_name  = userProfile && userProfile.last_name || '';

          return user;
        })
      );

      commit('usersListUpdated', users);
    },

    async changeUserRole({ state, commit }, { userId, roleType }) {
      const companyId     = state.currentCompany.company_id;
      const db            = firebase.firestore();

      const existingRoles = await db
        .collection('user_roles')
        .where('company_id', '==', companyId)
        .where('user_id', '==', userId)
        .get()
      ;

      const deleteBatch = db.batch();
      existingRoles.forEach((snapshot) => {
        deleteBatch.delete(snapshot.ref);
      });

      await deleteBatch.commit();

      await db.collection('user_roles').add({
        user_id   : userId,
        company_id: companyId,
        role_type : roleType
      });

      commit('userRoleChanged', { companyId, userId, roleType });
    },

    async setProjectLeadAccess({ state, commit }, { userId, projectIds }) {
      const db          = firebase.firestore();
      const batchUpdate = db.batch();
      const companyId   = state.currentCompany.company_id;

      const projects = await db
        .collection('projects')
        .where('company_id', '==', companyId)
        .get()
      ;

      projects.forEach(doc => {
        const project               = doc.data();
        const projectLeads          = new Set(project.project_leads || []);
        const projectSubcontractors = new Set(project.project_subcontractors || []);
        const projectReviewers      = new Set(project.project_reviewers || []);

        if (-1 === projectIds.indexOf(project.project_id)) {
          projectLeads.delete(userId);
        } else {
          projectLeads.add(userId);
          projectReviewers.delete(userId);
          projectSubcontractors.delete(userId);
        }

        batchUpdate.update(doc.ref, {
          project_reviewers     : Array.from(projectReviewers),
          project_leads         : Array.from(projectLeads),
          project_subcontractors: Array.from(projectSubcontractors)
        });
      });

      await batchUpdate.commit();
    },

    async setProjectReviewerAccess({ state, commit }, { userId, projectIds }) {
      const db          = firebase.firestore();
      const batchUpdate = db.batch();
      const companyId   = state.currentCompany.company_id;

      const projects = await db
        .collection('projects')
        .where('company_id', '==', companyId)
        .get()
      ;

      projects.forEach(doc => {
        const project               = doc.data();
        const projectLeads          = new Set(project.project_leads || []);
        const projectSubcontractors = new Set(project.project_subcontractors || []);
        const projectReviewers      = new Set(project.project_reviewers || []);

        if (-1 === projectIds.indexOf(project.project_id)) {
          projectReviewers.delete(userId);
        } else {
          projectReviewers.add(userId);
          projectLeads.delete(userId);
          projectSubcontractors.delete(userId);
        }

        batchUpdate.update(doc.ref, {
          project_reviewers     : Array.from(projectReviewers),
          project_leads         : Array.from(projectLeads),
          project_subcontractors: Array.from(projectSubcontractors),
        });
      });

      await batchUpdate.commit();
    },

    async setProjectSubcontractorAccess({ state, commit }, { userId, projectIds }) {
      const db          = firebase.firestore();
      const batchUpdate = db.batch();
      const companyId   = state.currentCompany.company_id;

      const projects = await db
        .collection('projects')
        .where('company_id', '==', companyId)
        .get()
      ;

      projects.forEach(doc => {
        const project               = doc.data();
        const projectLeads          = new Set(project.project_leads || []);
        const projectSubcontractors = new Set(project.project_subcontractors || []);
        const projectReviewers      = new Set(project.project_reviewers || []);

        if (-1 === projectIds.indexOf(project.project_id)) {
          projectSubcontractors.delete(userId);
        } else {
          projectSubcontractors.add(userId);
          projectReviewers.delete(userId);
          projectLeads.delete(userId);
        }

        batchUpdate.update(doc.ref, {
          project_reviewers     : Array.from(projectReviewers),
          project_leads         : Array.from(projectLeads),
          project_subcontractors: Array.from(projectSubcontractors),
        });
      });

      await batchUpdate.commit();
    },

    async removeUserFromCompany({ state, commit }, { userId }) {
      const companyId = state.currentCompany.company_id;
      const db        = firebase.firestore();

      const existingRoles = await db
        .collection('user_roles')
        .where('company_id', '==', companyId)
        .where('user_id', '==', userId)
        .get()
      ;

      const deleteBatch = db.batch();
      existingRoles.forEach((snapshot) => {
        deleteBatch.delete(snapshot.ref);
      });

      await deleteBatch.commit();

      commit('userRemovedFromCompany', { companyId, userId });
    },

    // ------------------------------------------------------
    //                    Gradbeni dnevnik
    // ------------------------------------------------------

    async createNewProject({ commit, state, dispatch }, project) {
      const db        = firebase.firestore();
      const projectId = project.projectId || UUID.v1();

      const projectDoc = {
        project_id : projectId,
        name       : project.name,
        company_id : state.currentCompany.company_id,
        uvodni_list: {
          sections: {
            1: {
              section_number: 1,
              vrsta_objekta: ' ',
              kraj_gradnje: '',
              kraj_za_vreme: '',
              vrsta_gradnje: ''
            },
            2: {
              section_number: 2,
              naziv: ''
            },
            3: {
              section_number: 3,
              naziv: '',
              odgovorni: '',
              vodja_gradbisca: '',
              vodja_posameznih_del: ''
            },
            4: {
              section_number: 4,
              stevilka_in_datum: ''
            },
            5: {
              section_number: 5,
              izdajatelj: '',
              stevilka_in_datum: ''
            },
            6: {
              section_number: 6,
              stevilka_in_datum: ''
            },
            7: {
              section_number: 7,
              naziv: '',
              odgovorna_oseba: '',
              odgovorna_oseba_posameznih_del: ''
            },
            8: {
              section_number: 8,
              naziv: '',
              arheolog: ''
            },
            9: {
              section_number: 9,
              odgovorni_gradbenega_dnevnika: '',
              odgovorni_obracunskih_mer: ''
            },
            10: {
              section_number: 10,
              stevilka_varnostnega_nacrta: '',
              izvajalec: '',
              koordinator_za_varnost: ''
            },
            11: {
              section_number: 11,
              datum: '',
              podpisi: ''
            }
          }
        }
      };

      await db.collection('projects').doc(projectId).set(projectDoc);
      commit('projectCreated', projectDoc);

      dispatch('setProjectLeads', { projectId: projectId, userIds: [state.currentUser.id] });
    },
    async changeProjectName({ commit, state }, { projectId, name }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .set({ name: name }, { merge: true })
      ;

      commit('projectNameChanged', { projectId, name });
    },
    async deleteProject({ commit, state }, projectId) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .delete()
      ;

      commit('projectDeleted', projectId);
    },
    async archiveProject({ commit, state }, projectId) {
      const db = firebase.firestore();
      await db
        .collection('projects')
        .doc(projectId)
        .set({ archived: true }, { merge: true })
      ;

      commit('projectArchived', projectId);
    },

    async unarchiveProject({ commit, state }, projectId) {
      const db = firebase.firestore();
      await db
        .collection('projects')
        .doc(projectId)
        .set({ archived: false }, { merge: true })
      ;

      commit('projectUnarchived', projectId);
    },

    async setAutomaticSiteLogs({ commit, state }, { projectId, enabled }) {
      const db = firebase.firestore();
      await db
        .collection('projects')
        .doc(projectId)
        .set({ automatic_site_logs_enabled: enabled }, { merge: true })
      ;

      commit('automaticSiteLogsChanged', { projectId, enabled });
    },
    async setProjectLeads({ commit, state }, { projectId, userIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          project_leads:  userIds
        })
      ;

      commit('projectLeadsChanged', { projectId, userIds });
    },

    async setProjectReviewers({ commit, state }, { projectId, userIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          project_reviewers:  userIds
        })
      ;

      commit('projectReviewersChanged', { projectId, userIds });
    },

    async setProjectSubcontractors({ commit, state }, { projectId, userIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          project_subcontractors: userIds
        })
      ;

      commit('projectSubcontractorsChanged', { projectId, userIds });
    },

    async setProjectSupervisors({ commit, state }, { projectId, userIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          project_supervisors:  userIds
        })
      ;

      commit('projectSupervisorsChanged', { projectId, userIds });
    },

    async setProjectNkks({ commit, state }, { projectId, userIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          project_nkks:  userIds
        })
      ;

      commit('projectNkksChanged', { projectId, userIds });
    },

    async setProjectZkks({ commit, state }, { projectId, userIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          project_zkks:  userIds
        })
      ;

      commit('projectZkksChanged', { projectId, userIds });
    },

    async setProjectHierarchy({ commit, state }, { projectId, childProjectIds }) {
      const db = firebase.firestore();

      await db
        .collection('projects')
        .doc(projectId)
        .update({
          subprojects:  childProjectIds
        })
      ;

      commit('projectHierarchyChanged', { projectId, childProjectIds });
    },

    async setUserProjectAccess({ dispatch, getters }, { userId, userRole, projectIds }) {
      getters
        .listOfProjects()
        .forEach(p => {
          if (!projectIds.includes(p.project_id)) {
            // User should be removed from all roles on project
            if ((p.project_leads).includes(userId)) {
              dispatch(
                'setProjectLeads',
                { projectId: p.project_id, userIds: p.project_leads.filter(uid => uid !== userId) }
              );
            }

            if (p.project_subcontractors.includes(userId)) {
              dispatch(
                'setProjectSubcontractors',
                { projectId: p.project_id, userIds: p.project_subcontractors.filter(uid => uid !== userId) }
              );
            }

            if (p.project_reviewers.includes(userId)) {
              dispatch(
                'setProjectReviewers',
                { projectId: p.project_id, userIds: p.project_reviewers.filter(uid => uid !== userId) }
              );
            }

            if (p.project_supervisors.includes(userId)) {
              dispatch(
                'setProjectSupervisors',
                { projectId: p.project_id, userIds: p.project_supervisors.filter(uid => uid !== userId) }
              );
            }

            if (p.project_nkks.includes(userId)) {
              dispatch(
                'setProjectNkks',
                { projectId: p.project_id, userIds: p.project_nkks.filter(uid => uid !== userId) }
              );
            }

            if (p.project_zkks.includes(userId)) {
              dispatch(
                'setProjectZkks',
                { projectId: p.project_id, userIds: p.project_zkks.filter(uid => uid !== userId) }
              );
            }
          } else {
            // Add user role
            if (UserRole.PROJECT_LEAD === userRole && !p.project_leads.includes(userId)) {
              dispatch(
                'setProjectLeads',
                { projectId: p.project_id, userIds: [...p.project_leads, userId] }
              );
            }

            if (UserRole.SUBCONTRACTOR === userRole && !p.project_subcontractors.includes(userId)) {
              dispatch(
                'setProjectSubcontractors',
                { projectId: p.project_id, userIds: [...p.project_subcontractors, userId] }
              );
            }

            if (UserRole.REVIEWER === userRole && !p.project_reviewers.includes(userId)) {
              dispatch(
                'setProjectReviewers',
                { projectId: p.project_id, userIds: [...p.project_reviewers, userId] }
              );
            }

            if (UserRole.SUPERVISOR === userRole && !p.project_supervisors.includes(userId)) {
              dispatch(
                'setProjectSupervisors',
                { projectId: p.project_id, userIds: [...p.project_supervisors, userId] }
              );
            }

            if (UserRole.NKK === userRole && !p.project_nkks.includes(userId)) {
              dispatch(
                'setProjectNkks',
                { projectId: p.project_id, userIds: [...p.project_nkks, userId] }
              );
            }

            if (UserRole.ZKK === userRole && !p.project_zkks.includes(userId)) {
              dispatch(
                'setProjectZkks',
                { projectId: p.project_id, userIds: [...p.project_zkks, userId] }
              );
            }
          }
        })
      ;
    },

    async addProjectLead({ dispatch, state, getters }, { projectId, userId }) {
      const projectLeads = new Set(
        getters
          .projectLeads(projectId)
          .map(u => u.id)
      );

      projectLeads.add(userId);

      dispatch('setProjectLeads', { projectId: projectId, userIds: Array.from(projectLeads) });
    },

    async addProjectSubcontractor({ dispatch, state, getters }, { projectId, userId }) {
      const projectLeads = new Set(
        getters
          .projectLeads(projectId)
          .map(u => u.id)
      );

      const projectSubcontractors = new Set(
        getters
          .projectSubcontractors(projectId)
          .map(u => u.id)
      );

      projectLeads.delete(userId);
      projectSubcontractors.add(userId);

      dispatch('setProjectLeads', { projectId: projectId, userIds: Array.from(projectLeads) });
      dispatch('setProjectSubcontractors', { projectId: projectId, userIds: Array.from(projectSubcontractors) });
    },

    async addProjectReviewer({ dispatch, state, getters }, { projectId, userId }) {
      const projectLeads = new Set(
        getters
          .projectLeads(projectId)
          .map(u => u.id)
      );

      const projectReviewers = new Set(
        getters
          .projectReviewers(projectId)
          .map(u => u.id)
      );

      projectLeads.delete(userId);
      projectReviewers.add(userId);

      dispatch('setProjectLeads', { projectId: projectId, userIds: Array.from(projectLeads) });
      dispatch('setProjectReviewers', { projectId: projectId, userIds: Array.from(projectReviewers) });
    },

    async addProjectSupervisor({ dispatch, state, getters }, { projectId, userId }) {
      const projectSupervisors = new Set(
        getters
          .projectSupervisors(projectId)
          .map(u => u.id)
      );

      projectSupervisors.add(userId);
      dispatch('setProjectSupervisors', { projectId: projectId, userIds: Array.from(projectSupervisors) });
    },

    async addProjectNkks({ dispatch, state, getters }, { projectId, userId }) {
      const projectNkks = new Set(
        getters
          .projectNkks(projectId)
          .map(u => u.id)
      );

      projectNkks.add(userId);
      dispatch('setProjectNkks', { projectId: projectId, userIds: Array.from(projectNkks) });
    },

    async addProjectZkks({ dispatch, state, getters }, { projectId, userId }) {
      const projectZkks = new Set(
        getters
          .projectZkks(projectId)
          .map(u => u.id)
      );

      projectZkks.add(userId);
      dispatch('setProjectZkks', { projectId: projectId, userIds: Array.from(projectZkks) });
    },

    /**
     * Creates new site log.
     *
     * @param commit
     * @param state
     * @param siteLogDetails
     * @returns {Promise<void>}
     */
    async createNewSiteLog({ dispatch, commit, state }, siteLogDetails) {
      const db              = firebase.firestore();
      const uvodniList      = state.uvodniListi.find(p => p.project_id === siteLogDetails.projectId);
      const previousSiteLog = await findPreviousSiteLog(siteLogDetails.projectId, siteLogDetails.date);

      const nextSequenceNumber = Math.max(
        ...state.siteLogs.filter(l => l.project_id == siteLogDetails.projectId).map(l => l.sequence)
      ) + 1;

      const siteLog = {
        project_id: siteLogDetails.projectId,
        company_id: state.currentCompany.company_id,
        sequence: nextSequenceNumber,
        date: siteLogDetails.date,
        delovni_cas: (previousSiteLog && previousSiteLog.delovni_cas) || {
          od: '08:00',
          do: '16:00'
        },
        vremenske_razmere: {
          0: {
            merjeno_ob: '08:00',
            vreme: '',
            temperatura: null,
            padavine_mm: null,
            vodostaj_cm: null,
            hitrost_vetra_ms: null,
            drugi_pogoji: ''
          },
          1: {
            merjeno_ob: '12:00',
            vreme: '',
            temperatura: null,
            padavine_mm: null,
            vodostaj_cm: null,
            hitrost_vetra_ms: null,
            drugi_pogoji: ''
          },
          2: {
            merjeno_ob: '16:00',
            vreme: '',
            temperatura: null,
            padavine_mm: null,
            vodostaj_cm: null,
            hitrost_vetra_ms: null,
            drugi_pogoji: ''
          }
        },
        delavci: (previousSiteLog && previousSiteLog.delavci) || {
          vodstvo: {
            izvajalci: 0,
            najeti: 0,
            kooperanti: 0
          },
          gradbena_dela: {
            izvajalci: 0,
            najeti: 0,
            kooperanti: 0
          },
          obrtniki: {
            izvajalci: 0,
            najeti: 0,
            kooperanti: 0
          },
          instalaterji: {
            izvajalci: 0,
            najeti: 0,
            kooperanti: 0
          },
          drugi: {
            izvajalci: 0,
            najeti: 0,
            kooperanti: 0
          }
        },
        stroji: (previousSiteLog && previousSiteLog.stroji) || {
          izvajalec: [],
          drugi: []
        },
        sporocila_narocniku: (previousSiteLog && previousSiteLog.sporocila_narocniku) || '',
        sestavil: (previousSiteLog && previousSiteLog.sestavil) || uvodniList.sections[9].odgovorni_gradbenega_dnevnika || '',
        odgovorni: (previousSiteLog && previousSiteLog.odgovorni) || uvodniList.sections[7].odgovorna_oseba || '',
        nadzorni: (previousSiteLog && previousSiteLog.nadzorni) || uvodniList.sections[3].vodja_gradbisca || '',
        dopolnilni_del: '',
        opombe_in_zahteve_odgovornega_vodje: '',
        opombe_in_zahteve_inspekcijskih_sluzb: '',
        opombe_in_zahteve_projektantov: '',
        opombe_in_zahteve_soglasodajalcev: '',
        opombe_ali_odgovori_izvajalca_del: '',

        // Signature images
        signature_sestavil: null,
        signature_odgovorni: null,
        signature_nadzorni: null,
      };

      const siteLogId = `${siteLogDetails.projectId}-${siteLogDetails.date}`;
      await db.collection('site_logs').doc(siteLogId).set(siteLog);

      commit('siteLogCreated', siteLog);

      const projectId   = siteLog.project_id;
      const date        = siteLogDetails.date;

      // Najprej alternativna lokacija, če dodane, sicer uporabi kraj gradnje
      const krajZaVreme =
        (state.uvodniListi.find(p => p.project_id === projectId).sections[1].kraj_za_vreme) ||
        (state.uvodniListi.find(p => p.project_id === projectId).sections[1].kraj_gradnje)
      ;

      // Now populate weather async
      Object.values(siteLog.vremenske_razmere).map(w => w.merjeno_ob).forEach(time => {

        const observationAt = `${siteLogDetails.date} ${time}`;

        const locationId = crypto
          .createHash('md5')
          .update(krajZaVreme.trim().toLowerCase())
          .digest('hex')
        ;

        db
          .collection('weather_observations')
          .where('location_id', '==', locationId)
          .where('observed_at', '==', observationAt)
          .limit(1)
          .get()
          .then(result => {
            if (1 === result.size) {
              const observation   = result.docs[0].data().observations;
              const clouds        = observation.clouds;
              const temperature   = observation.temperature;
              const precipitation = observation.precipitation;
              const wind          = observation.wind_and_direction;

              const index = Object
                .values(siteLog.vremenske_razmere)
                .map(w => w.merjeno_ob)
                .indexOf(time)
              ;

              const cloudsPath      = 'vremenske_razmere.' + index + '.vreme';
              const temperaturePath = 'vremenske_razmere.' + index + '.temperatura';
              const percipationPath = 'vremenske_razmere.' + index + '.padavine_mm';
              const windPath        = 'vremenske_razmere.' + index + '.hitrost_vetra_ms';

              dispatch('updateSiteLogField', { projectId: projectId, date: date, fieldPath: cloudsPath, value: clouds });
              dispatch('updateSiteLogField', { projectId: projectId, date: date, fieldPath: temperaturePath, value: temperature });
              dispatch('updateSiteLogField', { projectId: projectId, date: date, fieldPath: percipationPath, value: precipitation });
              dispatch('updateSiteLogField', { projectId: projectId, date: date, fieldPath: windPath, value: wind });
            }
          })
          .catch(/* Do nothing for now */)
        ;
      });
    },
    async deleteSiteLog({ commit, state }, { projectId, date }) {
      const db = firebase.firestore();

      const sitelog = state.siteLogs.find(d => d.project_id == projectId && d.date == date);
      if (sitelog.locked_for_edit) {
        return;
      }

      const siteLogId = `${projectId}-${date}`;

      await db
        .collection('site_logs')
        .doc(siteLogId)
        .delete()
      ;

      commit('siteLogDeleted', { projectId, date });
    },
    async fetchProjects({ commit, state }) {

      const db = firebase.firestore();

      // Fetch all projects
      (await db
        .collection('projects')
        .where('company_id', '==', state.currentCompany.company_id)
        .get())
        .docs
        .map(d => commit('projectCreated', d.data()))
      ;
    },

    /**
     * Fetches all site logs for a project
     *
     * @param commit
     * @param state
     * @param projectId
     * @returns {Promise<void>}
     */
    async fetchSiteLogs({ commit, state }, projectId) {
      const db = firebase.firestore();

      // Fetch all site logs for project
      const fetchedSiteLogs = await Promise.all((await db
        .collection('site_logs')
        .where('company_id', '==', state.currentCompany.company_id)
        .where('project_id', '==', projectId)
        .get())
        .docs
        .map(async (d) => {
          // Fetch attachments
          const attachments = (await d.ref.collection('attachments').get()).docs.map(d => d.data());

          return {
            ...d.data(),
            ...{ attachments: attachments }
          };
        })
      );

      commit('projectSiteLogsFetched', { projectId, fetchedSiteLogs });
    },

    async fetchSiteLogImages({ commit, state }, projectId) {
      const db       = firebase.firestore();
      const imagesId = `${state.currentCompany.company_id}-${projectId}`;

      (await db
        .collection('site_log_images')
        .doc(imagesId)
        .collection('images')
        .get())
        .forEach(doc => {
          commit(
            'siteLogImageCreated',
            Object.assign({
              project_id: projectId
            }, doc.data())
          );
        })
      ;
    },

    async addSignatureLog({ commit, state }, { projectId, date, signature }) {
      const db        = firebase.firestore();
      const siteLogId = `${projectId}-${date}`;
      const user      = state.currentUser;
      const company   = state.currentCompany;

      const payload = {
        type: 'SupervisorSignedSiteLog',
        company_id: company.company_id,
        project_id: projectId,
        user_id: user.id,
        notes: {
          op6: '',
          op6a: '',
          op6b: '',
          op6c: '',
          op6d: '',
        },
        signature: signature || null,
        date: date,
        site_log_id: siteLogId,
        created: (new Date()).toISOString()
      };

      await db
        .collection('site_logs')
        .doc(siteLogId)
        .collection('signature_logs')
        .add(payload)
      ;

      commit(
        'signatureLogCreated',
        payload,
      );
    },

    async fetchSiteLogSignatureLogs({ commit, state }, { projectId, date }) {
      const db        = firebase.firestore();
      const siteLogId = `${projectId}-${date}`;

      (await db
        .collection('site_logs')
        .doc(siteLogId)
        .collection('signature_logs')
        .get())
        .forEach(doc => {
          commit(
            'signatureLogCreated',
            {
              ...{ id: doc.id },
              ...doc.data()
            }
          );
        })
      ;
    },

    async addSiteLogAdditionalNote({ commit, state }, { projectId, date, section, note }) {
      const db        = firebase.firestore();
      const siteLogId = `${projectId}-${date}`;
      const user      = state.currentUser;
      const company   = state.currentCompany;

      const event = {
        type: 'SiteLogAdditionalNoteAdded',
        company_id: company.company_id,
        project_id: projectId,
        user_id: user.id,
        notes: {
          [section]: note
        },
        date: date,
        site_log_id: siteLogId,
        created: (new Date()).toISOString()
      };

      const doc = await db
        .collection('site_logs')
        .doc(siteLogId)
        .collection('signature_logs')
        .add(event)
      ;

      commit(
        'signatureLogCreated',
        {
          ...{ id: doc.id },
          ...event
        }
      );
    },

    async updateIntroductoryPageSection({ commit, state }, { value, projectId, fieldPath, sectionNumber }) {
      const db = firebase.firestore();

      commit('introductoryPageSectionUpdated', { projectId, sectionNumber, fieldPath, value });

      const projectRef   = db.collection('projects').doc(projectId);
      const sectionsDiff = {};

      sectionsDiff[sectionNumber] = {};
      sectionsDiff[sectionNumber][fieldPath] = value;

      await projectRef.set({
        uvodni_list: {
          sections: sectionsDiff
        }
      }, { merge: true});
    },

    async updateSiteLogField({ commit, state }, { projectId, date, fieldPath, value }) {
      commit('siteLogFieldUpdated', { projectId, date, fieldPath, value });

      const siteLogId   = `${projectId}-${date}`;
      const siteLogDiff = _setWith({}, fieldPath, value, Object);

      const db          = firebase.firestore();
      const siteLogRef  = db.collection('site_logs').doc(siteLogId);

      await siteLogRef.set(siteLogDiff, { merge: true });
    },

    async addMachineToSiteLog({ commit, state }, { projectId, date, type, description, count }) {
      const id = UUID.v1();

      commit('machineAddedToSiteLog', { projectId, date, id, type, description, count });

      const siteLogId       = `${projectId}-${date}`;
      const siteLog         = state.siteLogs.find(l => l.project_id == projectId && l.date == date);
      const machineCategory = Array.from(siteLog.stroji[type]);
      const existingMachine = Object.assign({}, machineCategory.find(m => m.id == id));

      if (existingMachine) {
        existingMachine.description = description;
        existingMachine.count       = count;
      } else {
        machineCategory.push({ id: id, description: description, count: count });
      }

      const db          = firebase.firestore();
      const siteLogRef  = db.collection('site_logs').doc(siteLogId);
      const siteLogDiff = { stroji: {} };
      siteLogDiff.stroji[type] = machineCategory;

      await siteLogRef.set(siteLogDiff, { merge: true });
    },

    async removeMachineFromSiteLog({ commit, state }, { projectId, date, id, type }) {
      const siteLogId = `${projectId}-${date}`;
      const siteLog = state.siteLogs.find(l => l.project_id == projectId && l.date == date);
      const existingMachine = siteLog.stroji[type].find(m => m.id == id);
      const machineCategory = siteLog.stroji[type].filter(m => m.id != id);

      commit('machineRemovedFromSiteLog', {projectId, date, id, type});

      if (existingMachine) {
        const db = firebase.firestore();
        const siteLogRef = db.collection('site_logs').doc(siteLogId);
        const siteLogDiff = { stroji: {} };
        siteLogDiff.stroji[type] = machineCategory;

        await siteLogRef.set(siteLogDiff, {merge: true});
      }
    },

    async updateMachineOnSiteLog({ commit, state }, { projectId, date, id, type, description, count }) {
      const siteLogId       = `${projectId}-${date}`;
      const siteLog         = state.siteLogs.find(l => l.project_id == projectId && l.date == date);
      const machineCategory = siteLog.stroji[type];
      const existingMachine = Object.assign({}, machineCategory.find(m => m.id == id));

      if (existingMachine) {

        if (description) {
          commit('machineDescriptionUpdated', { projectId, date, id, type, description });
          existingMachine.description = description;
        }

        if (count) {
          commit('machineCountUpdated', { projectId, date, id, type, count });
          existingMachine.count = count;
        }

        const db          = firebase.firestore();
        const siteLogRef  = db.collection('site_logs').doc(siteLogId);
        const siteLogDiff = { stroji: {} };
        siteLogDiff.stroji[type] = machineCategory;

        await siteLogRef.set(siteLogDiff, { merge: true });
      }
    },
    async sendJurnalToSignature({ commit, state }, { projectId, date, sequence, supervisors, qualityAssurance }) {

      const sitelog = state.siteLogs.find(d => d.project_id == projectId && d.date == date);
      if (sitelog.locked_for_edit) {
        // Nothing to do
        return;
      }

      const db = firebase.firestore();
      const user = state.users.find(u => u.id === state.currentUser.id);
      const siteLogId = `${projectId}-${date}`;
      const signatureLog = {
        type: 'SupervisorSignatureRequested',
        company_id: state.currentCompany.company_id,
        project_id: projectId,
        date: date,
        sequence: sequence,
        site_log_id: siteLogId,
        send_by: user,
        send_to: supervisors,
        quality_assurance: qualityAssurance,
        sent: false,
      };

      await db
        .collection('site_logs')
        .doc(siteLogId)
        .collection('signature_logs')
        .add(signatureLog)
      ;
    },

    /**
     *
     * @param commit
     * @param state
     * @param projectId
     * @param {string} image     Base64 encoded image
     * @param {string} imageName
     * @param imageDate
     * @param description
     * @returns {Promise<void>}
     */
    async uploadSiteLogImage({ commit, state }, { projectId, image, imageName, imageDate, description }) {
      const storage = firebase.storage();
      const db      = firebase.firestore();

      const imageId   = UUID.v1();
      const imagePath = `images/${state.currentCompany.company_id}/${projectId}/${imageId}.${imageName.split('.').pop()}`;

      const metadata = {
        name: imageName,
        customMetadata: {
          image_id     : imageId,
          company_id   : state.currentCompany.company_id,
          project_id   : projectId,
          date         : imageDate,
          original_name: imageName,
          description  : description
        }
      };

      const uploadedImage = await storage
        .ref(imagePath)
        .put(image, metadata)
      ;

      const imagesId    = `${state.currentCompany.company_id}-${projectId}`;
      const downloadUrl = await uploadedImage.ref.getDownloadURL();

      const imagesRef = db.collection('site_log_images').doc(imagesId);
      const imageData = {
        project_id   : projectId,
        image_id     : imageId,
        date         : imageDate,
        original_name: imageName,
        description  : description,
        download_url : downloadUrl,
        thumbs: {
          // Let's make full image as thumbnail for now (as thumbnails are generated in background)
          256 : [downloadUrl],
          1024: [downloadUrl]
        }
      };

      await imagesRef.collection('images').doc(imageId).set(imageData);

      commit('siteLogImageCreated', imageData);
    },

    /**
     * Updates date & description of an image
     *
     * @param commit
     * @param state
     * @param projectId
     * @param imageId
     * @param date
     * @param description
     * @returns {Promise<void>}
     */
    async updateSiteLogImage({ commit, state }, { projectId, imageId, date, description }) {
      const db         = firebase.firestore();
      const imagesId   = `${state.currentCompany.company_id}-${projectId}`;
      const imagesRef  = db.collection('site_log_images').doc(imagesId);

      await imagesRef
        .collection('images')
        .doc(imageId)
        .set({ date: date, description: description }, { merge: true})
      ;

      commit('siteLogImageUpdated', {
        image_id   : imageId,
        date       : date,
        description: description
      });
    },

    /**
     *
     * @param commit
     * @param state
     * @param projectId
     * @param imageId
     * @returns {Promise<void>}
     */
    async deleteSiteLogImage({ commit, state }, { projectId, imageId }) {
      const db        = firebase.firestore();
      const imagesId  = `${state.currentCompany.company_id}-${projectId}`;
      const imagesRef = db.collection('site_log_images').doc(imagesId);

      await imagesRef
        .collection('images')
        .doc(imageId)
        .delete()
      ;

      commit('siteLogImageDeleted', imageId);
    },

    /**
     *
     * @param commit
     * @param state
     * @param {String} projectId
     * @param {String} date
     * @param {File} attachment
     * @returns {Promise<void>}
     */
    async uploadSiteLogAttachment({ commit, state }, { projectId, date, attachment }) {
      const storage = firebase.storage();
      const db      = firebase.firestore();

      const attachmentId   = UUID.v1();
      const attachmentPath = `images/${state.currentCompany.company_id}/${projectId}/${attachmentId}.${attachment.name.split('.').pop()}`;

      const metadata = {
        name: attachment.name,
        customMetadata: {
          image_id     : attachmentId,
          company_id   : state.currentCompany.company_id,
          project_id   : projectId,
          original_name: attachment.name,
        }
      };

      const uploadedAttachment = await storage
        .ref(attachmentPath)
        .put(attachment, metadata)
      ;

      const siteLogId     = `${projectId}-${date}`;
      const downloadUrl   = await uploadedAttachment.ref.getDownloadURL();
      const attachmentDoc = {
        attachment_id: attachmentId,
        original_name: attachment.name,
        download_url : downloadUrl,
      };

      const attachmentsRef = db.collection('site_logs').doc(siteLogId);
      await attachmentsRef
        .collection('attachments')
        .doc(attachmentId)
        .set(attachmentDoc)
      ;

      commit('siteLogFileAttached', {
        projectId : projectId,
        date      : date,
        attachment: attachmentDoc
      });
    },
    async deleteSiteLogAttachment({ commit, state }, { projectId, date, attachmentId }) {
      const db = firebase.firestore();
      const siteLogId = `${projectId}-${date}`;
      const siteLogRef = db.collection('site_logs').doc(siteLogId);

      await siteLogRef
          .collection('attachments')
          .doc(attachmentId)
          .delete()
      ;

      commit('siteLogFileDeleted', {
        projectId   : projectId,
        date        : date,
        attachmentId: attachmentId
      });
    },

    /**
     * Find previous site log by date, then copies field to current site log
     *
     * @param commit
     * @param state
     * @param projectId
     * @param beforeDate
     * @param fieldPath
     * @return {Promise<void>}
     */
    async copyFieldFromPreviousSiteLog({ commit, state }, { projectId, beforeDate, fieldPath }) {
      const db      = firebase.firestore();
      const siteLog = await findPreviousSiteLog(projectId, beforeDate);

      if (null !== siteLog) {
        const value = siteLog[fieldPath];
        const date  = beforeDate;

        const siteLogId   = `${projectId}-${date}`;
        const siteLogDiff = _setWith({}, fieldPath, value, Object);
        const siteLogRef  = db.collection('site_logs').doc(siteLogId);

        await siteLogRef.set(siteLogDiff, { merge: true });

        commit('siteLogFieldUpdated', { projectId, date, fieldPath, value });
      }
    },

    async saveUserSignature({ commit, state }, signature) {
      const db = firebase.firestore();
      await db
        .collection('user_profiles')
        .doc(state.currentUser.id)
        .update({
          signature: signature,
        })
      ;

      commit('signatureSaved', signature);
    }
  },
});

/**
 * @param {string} projectId
 * @param {string} beforeDate Y-m-d
 * @return {Promise<object>}
 */
async function findPreviousSiteLog(projectId, beforeDate) {
  const db = firebase.firestore();

  const result = await db
    .collection('site_logs')
    .where('project_id', '==', projectId)
    .where('date', '<', beforeDate)
    .orderBy('date', 'desc')
    .limit(1)
    .get()
  ;

  return 1 === result.size ? result.docs[0].data() : null;
}

/**
 * @param {string} projectId
 * @param {string} beforeDate Y-m-d
 * @return {Promise<object>}
 */
async function findNextSiteLog(projectId, afterDate) {
  const db = firebase.firestore();

  const result = await db
    .collection('site_logs')
    .where('project_id', '==', projectId)
    .where('date', '>', beforeDate)
    .orderBy('date', 'asc')
    .limit(1)
    .get()
  ;

  return 1 === result.size ? result.docs[0].data() : null;
}

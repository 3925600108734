import can_write_project from './can_write_project';

export default (user, project) => {
  if (can_write_project(user, project)) {
    return true;
  }

  // We also allow supervisors to add_photos
  return (project.project_supervisors || []).includes(user.id);
};

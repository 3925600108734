<template>
  <v-app id="inspire">
    <v-content>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-form
              ref="form"
              @submit.prevent="register"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  dark
                  color="primary"
                >
                  <v-toolbar-title>Registracija</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-layout>
                    <v-text-field
                      v-model="ime"
                      prepend-icon="person"
                      name="ime"
                      label="Ime"
                      type="text"
                    />

                    <v-text-field
                      v-model="priimek"
                      prepend-icon="person"
                      name="priimek"
                      label="Priimek"
                      type="text"
                    />
                  </v-layout>

                  <v-text-field
                    v-model="email"
                    prepend-icon="email"
                    name="email"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                  />
                  <v-text-field
                    id="password"
                    v-model="password"
                    prepend-icon="lock"
                    name="password"
                    label="Geslo"
                    type="password"
                    :rules="passwordRules"
                  />
                  <v-text-field
                    v-model="companyName"
                    prepend-icon="work"
                    name="companyName"
                    label="Ime vašega podjetja"
                    type="text"
                    :rules="companyRules"
                  />
                  <v-text-field
                      v-model="phone"
                      prepend-icon="phone"
                      name="phone"
                      label="Telefonska številka"
                      type="text"
                      :rules="phoneRules"
                  >

                  </v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="primary"
                    type="submit"
                  >
                    Registriraj se
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
                />
              </v-card>
            </v-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import router from '../router';

  export default {
    data: () => ({
      loading: false,
      drawer: null,
      ime: '',
      priimek: '',
      email: '',
      password: '',
      companyName: '',
      phone: '',
      lastErrorCode: ''
    }),
    computed: {
      emailRules() {
        return [
          v => !!v || 'Vnos e-pošte je obvezen',
          v => /.+@.+/.test(v) || 'Vpišite veljaven e-poštni naslov',
          v => this.lastErrorCode !== 'auth/email-already-in-use' || 'Uporabnik že registriran'
        ]
      },
      passwordRules() {
        return [
          v => !!v || 'Vpišite geslo',
          v =>
            v.length >= 6 ||
            'Geslo mora biti dolgo vsaj 6 znakov',
          v => this.lastErrorCode !== 'auth/weak-password' || 'Šibko geslo'
        ]
      },
      companyRules() {
        return [
          v => !!v || 'Vpišite ime podjetja',
          v =>
            v.length >= 2 ||
            'Ime podjetja naj bo dolgo vsaj 2 znaka',
        ]
      },
      phoneRules() {
        return [
          v => !!v || 'Vpišite telefonsko številko',
          v =>
              v.length >= 9 ||
              'Vpišite veljavno telefonsko številko',
        ]
      }
    },
    mounted() {
      this.$store.subscribe((mutation, state) => {
        if ('userLoggedIn' === mutation.type) {
          router.push({name: 'dashboard'});
        }
      })
    },
    methods: {
      async register() {
        if (this.$refs.form.validate()) {
          this.loading = true;

          try {
            await this.$store.dispatch('registerNewUser', {
              firstName  : this.ime,
              lastName   : this.priimek,
              email      : this.email,
              password   : this.password,
              companyName: this.companyName,
              phone      : this.phone,
            });
          } catch (e) {
            this.lastErrorCode = e.code;
            this.$refs.form.validate();
            this.lastErrorCode = '';
          }

          this.loading = false;
        }
      }
    }
  }
</script>

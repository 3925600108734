<template>
  <v-app id="inspire">
    <v-content>
      <v-container
        fluid
        fill-height
      >
        <v-layout
          align-center
          justify-center
        >
          <v-flex
            xs12
            sm8
            md4
          >
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
              @submit.prevent="login"
            >
              <v-card class="elevation-12">
                <v-toolbar
                  dark
                  color="primary"
                >
                  <v-toolbar-title>Prijava</v-toolbar-title>
                  <v-spacer />
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="email"
                    prepend-icon="email"
                    name="email"
                    label="Email"
                    type="email"
                    :rules="emailRules"
                    required
                  />
                  <v-text-field
                    v-if="!emailLogin"
                    id="password"
                    v-model="password"
                    prepend-icon="lock"
                    name="password"
                    label="Geslo"
                    type="password"
                    :rules="passwordRules"
                    required
                  />
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <a
                    href="/forgotten"
                    class="pr-2"
                    data="cy-forgotten-password-link"
                  >
                    Pozabljeno geslo?
                  </a>
                  <a
                    href="/register"
                    class="pr-2"
                    data="cy-new-user-link"
                  >
                    Nov uporabnik?
                  </a>
                  <v-btn
                    data-cy="login"
                    color="primary"
                    :disabled="!valid"
                    type="submit"
                  >
                    Prijavi se
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  v-if="loading"
                  :indeterminate="true"
                />
              </v-card>
            </v-form>

            <v-dialog
              width="500"
              v-model="showEmailLinkWasSentDialog"
            >
              <v-card>
                <v-card-text>
                  <p>
                    <b>Povabilo je poteklo!</b>
                  </p>
                  <p>
                    Na vaš email naslov <b>{{email}} smo poslali novo povezavo</b> s katero se lahko prijavite v SiteLOG.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="showEmailLinkWasSentDialog=false">Zapri</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
  import router from '../router';
  import firebase from 'firebase';

  export default {
    props: {
      emailLogin  : { type: Boolean, default: false },
      suggestEmail: { type: String, default: '' },
      companyId   : { type: String,  default: '' }
    },
    data: () => ({
      loading: false,
      drawer: null,
      valid: false,
      email: '',
      password: '',
      lastErrorCode: '',
      showEmailLinkWasSentDialog: false
    }),
    computed: {
      emailRules() {
        return [
          v => !!v || 'Vnos e-pošte je obvezen',
          v => /.+@.+/.test(v) || 'Vpišite veljaven e-poštni naslov',
          v => this.lastErrorCode !== 'auth/user-not-found' || 'Uporabnik ne obstaja'
        ]
      },
      passwordRules() {
        return [
          v => !!v || 'Vpišite geslo',
          v =>
            v.length >= 6 ||
            'Geslo mora biti dolgo vsaj 6 znakov',
          v => this.lastErrorCode !== 'auth/wrong-password' || 'Napačno geslo'
        ]
      }
    },
    created() {
      if (this.suggestEmail) {
        this.email = this.suggestEmail;
      }
    },
    mounted() {
      this.$store.subscribe((mutation, state) => {
        if ('userLoggedIn' === mutation.type) {
          router.push({name: 'dashboard'});
        }
      });

      if (this.email && this.companyId) {
        this.$store.dispatch('userlogin', {
          email             : this.email,
          password          : this.password,
          emailLogin        : this.emailLogin,
          windowLocationHref: window.location.href,
          companyId         : this.companyId
        });
      }
    },
    methods: {
      async login () {
        if (this.$refs.form.validate()) {
          this.loading = true;

          try {
            await this.$store.dispatch('userlogin', {
              email             : this.email,
              password          : this.password,
              emailLogin        : this.emailLogin,
              windowLocationHref: window.location.href,
              companyId         : this.companyId
            });
          } catch (e) {
            this.lastErrorCode = e.code;
            this.$refs.form.validate();
            this.lastErrorCode = '';

            if (e.code === 'auth/expired-action-code' || e.code === 'auth/invalid-action-code') {
              this.showEmailLinkWasSentDialog = true;
              await this.resendLoginLink();
            }
          }

          this.loading = false;
        }
      },
      async resendLoginLink() {
        await firebase
            .auth()
            .sendPasswordResetEmail(
                this.email,
                {
                  url: window.location.origin + '/login',
                  handleCodeInApp: false
                }
            )
        ;
      }
    }
  }
</script>

<template>
  <div
    v-if="pageDataLoaded"
    v-touch="{
      left : () => swipe('left'),
      right: () => swipe('right'),
    }"
  >
    <!-- Dialogs, Alerts, Snackbars -->
    <v-snackbar
      v-model="swipedDate"
      :multi-line="true"
      :timeout="2000"
    >
      <p style="align-content: center">
        {{ humanReadableDate }}
      </p>

      <v-btn
        flat
        @click="swipedDate = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <!-- Weather refresh success/error status -->
    <v-snackbar
      v-model="weatherSnackbar.show"
      :timeout="weatherSnackbar.timeout"
      :color="weatherSnackbar.color"
      :multi-line="true"
    >
      {{ weatherSnackbar.text }}
    </v-snackbar>

    <h1>{{ project.name }}</h1>

    <v-tabs
      color="primary"
      :value="1"
    >
      <v-tab @click="goTo('/projects/' + $route.params.projectId)">
        <span class="white--text">
          Uvodni list
        </span>
      </v-tab>
      <v-tab @click="goTo('/projects/' + $route.params.projectId+ '/journals')">
        <span class="white--text">
          Dnevni listi
        </span>
      </v-tab>
      <v-tab @click="goTo('/projects/' + $route.params.projectId+ '/pictures')">
        <span class="white--text">
          Slike
        </span>
      </v-tab>
    </v-tabs>

    <v-form>
      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>Dnevni list na {{ humanReadableDate }}</v-toolbar-title>
          <v-spacer />
          <v-chip v-show="isLocked">✓ Podpisan</v-chip>
          <div v-if="canEditSitelog">
            <SendJournalToSignature
                v-bind:projectId="dnevniList.project_id"
                v-bind:date="dnevniList.date"
                v-bind:sequence="sequence"
                v-bind:supervisors="supervisors"
                v-bind:nkks="nkks"
                v-bind:zkks="zkks"
                v-bind:isSigned="isLocked"
            ></SendJournalToSignature>
          </div>
          <div>
            <v-btn
              icon
              @click="exportAsPdf"
            >
              <v-icon>print</v-icon>
            </v-btn>
          </div>
          <div>
            <v-switch
              v-model="printWithImages"
              hide-details
              label="Natisni s slikami"
            />
          </div>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            :value="osnovniPodatki.izvajalec"
            label="Izvajalec"
            disabled
          />
          <v-text-field
            :value="osnovniPodatki.objekt"
            label="Objekt"
            disabled
          />
          <v-text-field
            :value="osnovniPodatki.investitor"
            label="Naročnik"
            disabled
          />
          <v-text-field
            :value="sequence"
            label="Dnevno poročilo številka"
            disabled
          />
          <v-text-field
            slot="activator"
            :value="humanReadableDate"
            label="za dan"
            disabled
          />

          <v-text-field
            :value="dnevniList.delovni_cas.od"
            :disabled="!canEditSitelog || isLocked"
            label="Delovni čas od"
            @change="updateSiteLogField($event, 'delovni_cas.od')"
          />
          <v-text-field
            :value="dnevniList.delovni_cas.do"
            :disabled="!canEditSitelog || isLocked"
            label="Delovni čas do"
            @change="updateSiteLogField($event, 'delovni_cas.do')"
          />
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Vremenske razmere
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <table class="v-datatable v-table theme--light">
            <thead>
              <tr>
                <th>
                  Vremenske razmere ob uri
                  <v-tooltip bottom>
                    <v-icon
                      slot="activator"
                    >
                      info
                    </v-icon>
                    <span>Vpiši vsaj za jutranji, dopoldanski in popoldanski čas</span>
                  </v-tooltip>
                </th>

                <th
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.merjeno_ob"
                    :disabled="!canEditSitelog || isLocked"
                    label="Ura"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.merjeno_ob')"
                    @focus="$event.target.select()"
                  />

                  <v-btn
                    icon
                    :disabled="!canEditSitelog || isLocked"
                    @click="populateWeather(index, vremenskeRazmere.merjeno_ob, true)"
                  >
                    <v-icon>refresh</v-icon>
                  </v-btn>

                  <v-btn
                      icon
                      :disabled="!canEditSitelog || isLocked"
                      @click="clearWeather(index)"
                  >
                    <v-icon>delete</v-icon>
                  </v-btn>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>vreme</td>
                <td
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.vreme"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.vreme')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>temperatura zraka °C</td>
                <td
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.temperatura"
                    :disabled="!canEditSitelog  || isLocked"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.temperatura')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>višina padavin (sneg, dež)</td>
                <td
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.padavine_mm"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.padavine_mm')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>vodostaj</td>
                <td
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.vodostaj_cm"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.vodostaj_cm')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>hitrost vetra (m/s) in smer</td>
                <td
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.hitrost_vetra_ms"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.hitrost_vetra_ms')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>drugi pogoji</td>
                <td
                  v-for="(vremenskeRazmere, index) in dnevniList.vremenske_razmere"
                  :key="vremenskeRazmere.merjeno_ob"
                >
                  <v-text-field
                    :value="vremenskeRazmere.drugi_pogoji"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'vremenske_razmere.' + index + '.drugi_pogoji')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Delavci na gradbišču
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <table class="v-datatable v-table theme--light">
            <thead>
              <tr>
                <th />
                <th>delovna sila izvajalca</th>
                <th>najeta dolovna sila</th>
                <th>podizvajalci, kooperanti</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>vodstvo</td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.vodstvo.izvajalci"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.vodstvo.izvajalci')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.vodstvo.najeti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.vodstvo.najeti')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.vodstvo.kooperanti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.vodstvo.kooperanti')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>delavci za gradbena dela</td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.gradbena_dela.izvajalci"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.gradbena_dela.izvajalci')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.gradbena_dela.najeti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.gradbena_dela.najeti')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.gradbena_dela.kooperanti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.gradbena_dela.kooperanti')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>delavci za obrtna dela</td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.obrtniki.izvajalci"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.obrtniki.izvajalci')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.obrtniki.najeti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.obrtniki.najeti')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.obrtniki.kooperanti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.obrtniki.kooperanti')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>delavci za inštalacijska dela</td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.instalaterji.izvajalci"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.instalaterji.izvajalci')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.instalaterji.najeti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.instalaterji.najeti')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.instalaterji.kooperanti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.instalaterji.kooperanti')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td>delavci za druga dela</td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.drugi.izvajalci"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.drugi.izvajalci')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.drugi.najeti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.drugi.najeti')"
                    @focus="$event.target.select()"
                  />
                </td>
                <td>
                  <v-text-field
                    placeholder="0"
                    :value="dnevniList.delavci.drugi.kooperanti"
                    :disabled="!canEditSitelog || isLocked"
                    @change="updateSiteLogField($event, 'delavci.drugi.kooperanti')"
                    @focus="$event.target.select()"
                  />
                </td>
              </tr>
              <tr>
                <td><b>skupaj</b></td>
                <td>
                  <v-text-field
                    :value="delavciIzvajalciSkupaj"
                    disabled
                  />
                </td>
                <td>
                  <v-text-field
                    :value="delavciNajetiSkupaj"
                    disabled
                  />
                </td>
                <td>
                  <v-text-field
                    :value="delavciDrugiSkupaj"
                    disabled
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Stroji
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <h3>Izvajalčevi</h3>

          <v-container
            v-for="stroj in dnevniList.stroji.izvajalec"
            :key="stroj.id"
            grid-list-md
            text-xs-center
          >
            <v-layout>
              <v-flex
                xs8
              >
                <v-text-field
                  :disabled="!canEditSitelog || isLocked"
                  :value="stroj.description"
                  label="Opis stroja"
                  @change="updateMachineDescription($event, stroj.id, 'izvajalec')"
                />
              </v-flex>

              <v-flex
                xs3
              >
                <v-text-field
                  :disabled="!canEditSitelog || isLocked"
                  :value="stroj.count"
                  label="Število strojev"
                  @change="updateMachineCount($event, stroj.id, 'izvajalec')"
                  @focus="$event.target.select()"
                />
              </v-flex>

              <v-flex
                xs1
              >
                <v-btn
                  icon
                  :disabled="!canEditSitelog || isLocked"
                  @click="odstraniStroj('izvajalec', stroj.id)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>

          <v-btn
            v-show="!isLocked"
            :disabled="!canEditSitelog || isLocked"
            @click="dodajStroj('izvajalec')"
          >
            Dodaj stroj
          </v-btn>

          <h3>Drugi</h3>

          <v-container
            v-for="stroj in dnevniList.stroji.drugi"
            :key="stroj.id"
            grid-list-md
            text-xs-center
          >
            <v-layout>
              <v-flex
                xs8
              >
                <v-text-field
                  :value="stroj.description"
                  :disabled="!canEditSitelog || isLocked"
                  label="Opis stroja"
                  @change="updateMachineDescription($event, stroj.id, 'drugi')"
                />
              </v-flex>

              <v-flex
                xs3
              >
                <v-text-field
                  :value="stroj.count"
                  :disabled="!canEditSitelog || isLocked"
                  label="Število strojev"
                  @change="updateMachineCount($event, stroj.id, 'drugi')"
                  @focus="$event.target.select()"
                />
              </v-flex>

              <v-flex
                xs1
              >
                <v-btn
                  icon
                  :disabled="!canEditSitelog || isLocked"
                  @click="odstraniStroj('drugi', stroj.id)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>

          <v-btn
            v-show="!isLocked"
            :disabled="!canEditSitelog || isLocked"
            @click="dodajStroj('drugi')"
          >
            Dodaj stroj
          </v-btn>
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Sporočila naročniku
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="dnevniList.sporocila_narocniku"
            :disabled="!canEditSitelog || isLocked"
            label="kratek tehnični opis del, ki se na objektu dnevno izvajajo, podatki o nepredvidenih in interventnih delih"
            auto-grow
            @change="updateSiteLogField($event, 'sporocila_narocniku')"
          />
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Sestavil
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            :value="dnevniList.sestavil"
            :disabled="!canEditSitelog || isLocked"
            label="ime in priimek, podpis"
            @change="updateSiteLogField($event, 'sestavil')"
          />

          <Signature
              signature_id="signature-sestavil"
              show_sign_button="yes"
              :project_id="project.project_id"
              :date="dnevniList.date"
              :is_locked="isLocked"
              :can_edit_project="canEditSitelog"
              :signature="dnevniList.signature_sestavil"
          ></Signature>

        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Odgovorni nadzornik oz. odgovorni nadzornih posameznih del
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            :value="dnevniList.odgovorni"
            :disabled="!(canEditSitelog || showSignButtonForSupervisor) || isLocked"
            label="ime in priimek, podpis"
            @change="updateSiteLogField($event, 'odgovorni')"
          />

          <Signature
              signature_id="signature-odgovorni"
              :show_sign_button="showSignButtonForSupervisor ? 'yes' : 'no'"
              :project_id="project.project_id"
              :date="dnevniList.date"
              :is_locked="isLocked"
              :can_edit_project="showSignButtonForSupervisor"
              :signature="dnevniList.signature_odgovorni"
          ></Signature>
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Odgovorni vodja del oz odgovorni vodja posameznih del
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            :value="dnevniList.nadzorni"
            label="ime in priimek, podpis"
            :disabled="!canEditSitelog || isLocked"
            @change="updateSiteLogField($event, 'nadzorni')"
          />

          <Signature
              signature_id="signature-nadzorni"
              show_sign_button="yes"
              :project_id="project.project_id"
              :date="dnevniList.date"
              :is_locked="isLocked"
              :can_edit_project="canEditSitelog"
              :signature="dnevniList.signature_nadzorni"
          ></Signature>
        </v-card-text>
      </v-card>

      <div
        class="secondary"
        style="height: 3px;"
      />

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Dopolnilni del
          </v-toolbar-title>
        </v-toolbar>
        <v-toolbar dense>
          <v-toolbar-title>
            &gt; Sporočila izvajalcu
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-textarea
            :value="dnevniList.dopolnilni_del"
            :disabled="!canEditSitelog || isLocked"
            label="Dodatne ugotovitve, navodila, sporočila in pripombe ter vpisi in vrisi (sporočila izvajalcu)"
            auto-grow
            @change="updateSiteLogField($event, 'dopolnilni_del')"
          />
        </v-card-text>

        <SignatureNote
            v-for="note in signatureNotes('op6')"
            :key="note.id"
            :display_name="note.display_name"
            :user_role="note.user_role"
            :signature="note.signature"
            :note="note.note"
            :created="note.created"
        ></SignatureNote>

        <AddAdditionalNote
          title="Sporočila izvajalcu"
          description="Dodatne ugotovitve, navodila, sporočila in pripombe ter vpisi in vrisi (sporočila izvajalcu)"
          section="op6"
          :project-id="project.project_id"
          :date="dnevniList.date"
          :allow="canAddAditionalNotes"
        >
        </AddAdditionalNote>

        <v-toolbar dense>
          <v-toolbar-title>
            &gt; Opombe in zahteve odgovornega vodje projekta
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="dnevniList.opombe_in_zahteve_odgovornega_vodje"
            :disabled="!canEditSitelog || isLocked"
            label="Opombe in zahteve odgovornega vodje projekta za pridobitev gradbenega dovoljenja"
            auto-grow
            @change="updateSiteLogField($event, 'opombe_in_zahteve_odgovornega_vodje')"
          />
        </v-card-text>

        <SignatureNote
            v-for="note in signatureNotes('op6a')"
            :key="note.id"
            :display_name="note.display_name"
            :user_role="note.user_role"
            :signature="note.signature"
            :note="note.note"
            :created="note.created"
        ></SignatureNote>

        <AddAdditionalNote
            title="Opombe in zahteve odgovornega vodje projekta"
            description="Opombe in zahteve odgovornega vodje projekta za pridobitev gradbenega dovoljenja"
            section="op6a"
            :project-id="project.project_id"
            :date="dnevniList.date"
            :allow="canAddAditionalNotes"
        >
        </AddAdditionalNote>

        <v-toolbar dense>
          <v-toolbar-title>
            &gt; Opombe in zahteve inšpekcijskih služb
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="dnevniList.opombe_in_zahteve_inspekcijskih_sluzb"
            :disabled="!canEditSitelog || isLocked"
            label="Opombe in zahteve inšpekcijskih služb"
            auto-grow
            @change="updateSiteLogField($event, 'opombe_in_zahteve_inspekcijskih_sluzb')"
          />
        </v-card-text>

        <SignatureNote
            v-for="note in signatureNotes('op6b')"
            :key="note.id"
            :display_name="note.display_name"
            :user_role="note.user_role"
            :signature="note.signature"
            :note="note.note"
            :created="note.created"
        ></SignatureNote>

        <AddAdditionalNote
            title="Opombe in zahteve inšpekcijskih služb"
            description="Opombe in zahteve inšpekcijskih služb"
            section="op6b"
            :project-id="project.project_id"
            :date="dnevniList.date"
            :allow="canAddAditionalNotes"
        >
        </AddAdditionalNote>

        <v-toolbar dense>
          <v-toolbar-title>
            &gt; Opombe in zahteve odgovornih projektantov ter strokovnjakov
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="dnevniList.opombe_in_zahteve_projektantov"
            :disabled="!canEditSitelog || isLocked"
            label="Opombe in zahteve odgovornih projektantov ter strokovnjakov z drugih področij"
            auto-grow
            @change="updateSiteLogField($event, 'opombe_in_zahteve_projektantov')"
          />
        </v-card-text>

        <SignatureNote
            v-for="note in signatureNotes('op6c')"
            :key="note.id"
            :display_name="note.display_name"
            :user_role="note.user_role"
            :signature="note.signature"
            :note="note.note"
            :created="note.created"
        ></SignatureNote>

        <AddAdditionalNote
            title="Opombe in zahteve odgovornih projektantov ter strokovnjakov"
            description="Opombe in zahteve odgovornih projektantov ter strokovnjakov z drugih področij"
            section="op6c"
            :project-id="project.project_id"
            :date="dnevniList.date"
            :allow="canAddAditionalNotes"
        >
        </AddAdditionalNote>

        <v-toolbar dense>
          <v-toolbar-title>
            &gt; Opombe in zahteve soglasodajalcev
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="dnevniList.opombe_in_zahteve_soglasodajalcev"
            :disabled="!canEditSitelog || isLocked"
            label="Opombe in zahteve soglasodajalcev"
            auto-grow
            @change="updateSiteLogField($event, 'opombe_in_zahteve_soglasodajalcev')"
          />
        </v-card-text>

        <SignatureNote
            v-for="note in signatureNotes('op6d')"
            :key="note.id"
            :display_name="note.display_name"
            :user_role="note.user_role"
            :signature="note.signature"
            :note="note.note"
            :created="note.created"
        ></SignatureNote>

        <AddAdditionalNote
            title="Opombe in zahteve soglasodajalcev"
            description="Opombe in zahteve soglasodajalcev"
            section="op6d"
            :project-id="project.project_id"
            :date="dnevniList.date"
            :allow="canAddAditionalNotes"
        >
        </AddAdditionalNote>

        <v-toolbar dense>
          <v-toolbar-title>
            Opombe ali odgovori izvajalca del
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-textarea
            :value="dnevniList.opombe_ali_odgovori_izvajalca_del"
            :disabled="!canEditSitelog || isLocked"
            label="Opombe ali odgovori izvajalca del"
            auto-grow
            @change="updateSiteLogField($event, 'opombe_ali_odgovori_izvajalca_del')"
          />
        </v-card-text>
      </v-card>

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Slike
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <JournalImageUploader
            v-if="canAddPhotos"
            :project-id="project.project_id"
            :date="dnevniList.date"
          >
          </JournalImageUploader>

          <JournalImages
            :project-id="project.project_id"
            :date="dnevniList.date"
            :can-edit-project="canEditSitelog"
            :images="journalImages"
          >

          </JournalImages>

        </v-card-text>
      </v-card>

      <v-card>
        <v-toolbar dense>
          <v-toolbar-title>
            Priponke
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <JournalAttachments
              :project-id="project.project_id"
              :date="dnevniList.date"
              :can-upload="canEditSitelog && !isLocked"
              :attachments="attachments"
          ></JournalAttachments>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import crypto from 'crypto';
  import firebase from 'firebase';
  import router from '../router';
  import moment from 'moment';
  import can_write_sitelog from '../model/user/permissions/can_write_sitelog';
  import SendJournalToSignature from '@/views/components/SendJournalToSignature';
  import SignatureNote from '@/views/components/SignatureNote';
  import JournalImageUploader from '@/views/components/JournalImageUploader';
  import JournalImages from '@/views/components/JournalImages';
  import AddAdditionalNote from '@/views/components/AddAdditionalNote';
  import can_add_additional_notes_to_journal from '@/model/user/permissions/can_add_additional_notes_to_journal';
  import Signature from '@/views/components/Signature';
  import JournalAttachments from "./components/JournalAttachments";
  import { UserRole } from '../model/user/UserRole';
  import can_add_photos from '../model/user/permissions/can_add_photos';

  export default {
    components: {
      JournalAttachments,
      JournalImages,
      SendJournalToSignature,
      SignatureNote,
      JournalImageUploader,
      AddAdditionalNote,
      Signature,
    },
    data: () => ({
      pageDataLoaded: false,
      pageLoadingPromise: null,

      // Print options
      printWithImages: true,

      // Snackbars
      swipedDate: false,
      weatherSnackbar: {
        show: false,
        color: 'success',
        timeout: 2000,
        text: ''
      },
    }),
    async created() {
      this.pageLoadingPromise = Promise.all([
        this.$store.dispatch('fetchProjects'),
        this.$store.dispatch('fetchSiteLogs', this.$route.params.projectId),
        this.$store.dispatch('fetchUsers'),
      ]);

      await this.pageLoadingPromise;
      this.pageDataLoaded = true;

      this.$store.dispatch('fetchSiteLogSignatureLogs', {
        projectId: this.$route.params.projectId,
        date: this.$route.params.journalDate
      });
    },
    computed: {
      // canEditSitelog() {
      canEditSitelog() {
        const currentUser = this.$store.state.currentUser;

        return can_write_sitelog(currentUser, this.project);
      },
      canAddAditionalNotes() {
        if (this.isLocked) {
          return false;
        }

        const currentUser = this.$store.state.currentUser;

        return can_add_additional_notes_to_journal(currentUser, this.project);
      },
      canAddPhotos() {
        return can_add_photos(this.$store.state.currentUser, this.project);
      },
      showSignButtonForSupervisor() {
        const currentUser = this.$store.state.currentUser;

        if (this.isLocked || currentUser.role !== UserRole.SUPERVISOR) {
          // Zaklenjenih dnevnih listov ni več možno podpisati
          // Samo supervisor lahko podpiše
          return false;
        }

        const sentToCurrentUser = (this.dnevniList.signature_logs || [])
            .find(e => {
              return (
                e.type === 'SupervisorSignatureRequested' &&
                (e.send_to || []).find(r => r.id === currentUser.id)
              );
            })

        return !!sentToCurrentUser;
      },
      isLocked() {
        return this.dnevniList.locked_for_edit === true;
      },
      sequence() {
        return this.$route.params.sequence || 1;
      },
      humanReadableDate() {
        return moment(this.$route.params.journalDate).format('dddd, DD.MM.YYYY');
      },
      project() {
        return this.$store.state.listOfProjects.find(p => p.project_id == this.$route.params.projectId);
      },
      osnovniPodatki() {

        const objekt     = this.$store.getters.uvodniListSection(this.$route.params.projectId, 1);
        const investitor = this.$store.getters.uvodniListSection(this.$route.params.projectId, 2);
        const izvajalec  = this.$store.getters.uvodniListSection(this.$route.params.projectId, 3);

        return {
          izvajalec: izvajalec.naziv,
          objekt: objekt.vrsta_objekta,
          investitor: investitor. naziv
        };
      },
      dnevniList() {
        return this.$store.getters.dnevniList(this.$route.params.projectId, this.$route.params.journalDate);
      },
      delavciIzvajalciSkupaj() {
        return (
          (parseInt(this.dnevniList.delavci.vodstvo['izvajalci']) || 0) +
          (parseInt(this.dnevniList.delavci.gradbena_dela['izvajalci']) || 0) +
          (parseInt(this.dnevniList.delavci.obrtniki['izvajalci']) || 0) +
          (parseInt(this.dnevniList.delavci.instalaterji['izvajalci']) || 0) +
          (parseInt(this.dnevniList.delavci.drugi['izvajalci']) || 0)
        );
      },
      delavciNajetiSkupaj() {
        return (
          (parseInt(this.dnevniList.delavci.vodstvo['najeti']) || 0) +
          (parseInt(this.dnevniList.delavci.gradbena_dela['najeti']) || 0) +
          (parseInt(this.dnevniList.delavci.obrtniki['najeti']) || 0) +
          (parseInt(this.dnevniList.delavci.instalaterji['najeti']) || 0) +
          (parseInt(this.dnevniList.delavci.drugi['najeti']) || 0)
        );
      },
      delavciDrugiSkupaj() {
        return (
          (parseInt(this.dnevniList.delavci.vodstvo['kooperanti']) || 0) +
          (parseInt(this.dnevniList.delavci.gradbena_dela['kooperanti']) || 0) +
          (parseInt(this.dnevniList.delavci.obrtniki['kooperanti']) || 0) +
          (parseInt(this.dnevniList.delavci.instalaterji['kooperanti']) || 0) +
          (parseInt(this.dnevniList.delavci.drugi['kooperanti']) || 0)
        );
      },
      attachments() {
        return this.dnevniList.attachments || [];
      },
      supervisors() {
        return this
            .$store
            .getters
            .projectSupervisors(this.$route.params.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
          ;
      },
      nkks() {
        return this
            .$store
            .getters
            .projectNkks(this.$route.params.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
          ;
      },
      zkks() {
        return this
            .$store
            .getters
            .projectZkks(this.$route.params.projectId)
            .map(u => {
              return {
                ...u,
                display_name: `${u.first_name} ${u.last_name} <${u.role}>`,
                disabled    : u.role === 'admin'
              };
            })
            ;
      },
      journalImages() {
        return this.$store.getters.siteLogImages(this.$route.params.projectId)
          .filter(i => i.date === this.$route.params.journalDate)
      },
      signatureNotes() {
        return section => {
          return (this.dnevniList.signature_logs || [])
              .filter(e => e.notes && e.notes[section])
              .filter(e => e.created && e.user_id)
              .map(e => {
                const user = this.$store.state.users.find(u => u.id === e.user_id);

                return {
                  display_name: `${user.first_name} ${user.last_name}`,
                  signature: e.signature,
                  note: e.notes[section],
                  created: e.created,
                  user_role: user.role
                };
              })
              .sort((a, b) => a.created - b.created)
            ;
        };
      },
    },
    methods: {
      swipe(direction) {
        let goToDate     = null;
        let goToSequence = null;

        if ('right' === direction) {
          // Previous sitelog
          goToDate     = this.$store.getters.previousSiteLogDate(this.$route.params.projectId, this.$route.params.journalDate);
          goToSequence = _.padStart(parseInt(this.sequence) - 1, 3, '0');
        } else if ('left' === direction) {
          // Next sitelog
          goToDate     = this.$store.getters.nextSiteLogDate(this.$route.params.projectId, this.$route.params.journalDate);
          goToSequence = _.padStart(parseInt(this.sequence) + 1, 3, '0');
        }

        if (null !== goToDate && null !== goToSequence) {
          this.goTo(`/projects/${this.$route.params.projectId}/journals/${goToDate}/${goToSequence}?swipe=true`);
          this.swipedDate = true;
        }
      },
      goTo(href) {
        router.push(href);
      },
      dodajStroj(tipStroja) {
        this.$store.dispatch('addMachineToSiteLog', {
          projectId: this.$route.params.projectId,
          date: this.$route.params.journalDate,
          type: tipStroja,
          description: '',
          count: 1
        });
      },
      odstraniStroj(tipStroja, strojId) {
        this.$store.dispatch('removeMachineFromSiteLog', {
          projectId: this.$route.params.projectId,
          date: this.$route.params.journalDate,
          id: strojId,
          type: tipStroja
        });
      },
      updateMachineDescription(description, id, type) {
        this.$store.dispatch('updateMachineOnSiteLog', {
          projectId: this.$route.params.projectId,
          date: this.$route.params.journalDate,
          id: id,
          type: type,
          description: description
        });
      },
      updateMachineCount(count, id, type) {
        this.$store.dispatch('updateMachineOnSiteLog', {
          projectId: this.$route.params.projectId,
          date: this.$route.params.journalDate,
          id: id,
          type: type,
          count: count
        });
      },
      updateSiteLogField(value, fieldPath) {
        const projectId = this.$route.params.projectId;
        const date      = this.$route.params.journalDate;

        this.$store.dispatch('updateSiteLogField', { projectId, date, fieldPath, value });
      },
      async populateWeather(index, time, showAlert = false) {
        const db = firebase.firestore();

        const krajZaVreme   =
          (this.$store.getters.uvodniListSection(this.$route.params.projectId, 1).kraj_za_vreme) ||
          (this.$store.getters.uvodniListSection(this.$route.params.projectId, 1).kraj_gradnje)
        ;
        const observationAt = `${this.$route.params.journalDate} ${time}`;

        const locationId = crypto
          .createHash('md5')
          .update(krajZaVreme.trim().toLowerCase())
          .digest('hex')
        ;

        const result = await db
          .collection('weather_observations')
          .where('location_id', '==', locationId)
          .where('observed_at', '==', observationAt)
          .limit(1)
          .get()
        ;

        if (1 === result.size) {
          const observation = result.docs[0].data().observations;

          this.updateSiteLogField(observation.clouds, 'vremenske_razmere.' + index + '.vreme');
          this.updateSiteLogField(observation.temperature, 'vremenske_razmere.' + index + '.temperatura');
          this.updateSiteLogField(observation.precipitation, 'vremenske_razmere.' + index + '.padavine_mm');
          this.updateSiteLogField(observation.wind_and_direction, 'vremenske_razmere.' + index + '.hitrost_vetra_ms');

          this.weatherSnackbar.text    = `Vreme za lokacijo "${krajZaVreme}" ob ${moment(observationAt).format('H:mm')} je osveženo`;
          this.weatherSnackbar.color   = 'success';
          this.weatherSnackbar.timeout = 2000;
          this.weatherSnackbar.show    = true;
        } else {
          const requestedLocation = (
            await db
              .collection('weather_locations')
              .doc(locationId)
              .get())
            .data()
          ;

          let text = `Vreme za kraj "${krajZaVreme}" ob ${moment(observationAt).format('H:mm')} ni na voljo.`;

          if (krajZaVreme.length < 1) {
            text = `Prosimo izpolnite "Kraj gradnje" na uvodne listu! Kraj gradnje potrebujemo za pridobitev vremena.`;
          } else if (moment(observationAt) > moment()) {
            text = `
              Aplikacija omogoča samodjeno izpoljevanje preteklega vremena.
              Vremenskih napovedi ne pridobivamo. Prosimo poskusite ponovno po ${moment(observationAt).format('DD.MM.YYYY H:mm')}.
            `;
          } else if (requestedLocation && requestedLocation.ok === true) {
            text = `
              Vreme za kraj "${krajZaVreme}" sicer spremljamo, vendar žal za čas ob ${moment(observationAt).format('H:mm')} ni na voljo.
              Če se napaka pogosto pojavlja nas prosimo obvesite na support@sitelog.si.
            `;
          } else if (requestedLocation && requestedLocation.ok === false) {
            text = `
              Vremena za kraj "${krajZaVreme}" ne moremo pridobiti. Poskusite lahko z alternativno lokacijo za vreme, ki jo lahko spremenite na uvodnem listu. Potem poskusite ponovno.
              Če težave ne morete odpravite, nam pišite na support@sitelog.si.
            `;
          }

          this.weatherSnackbar.text    = text;
          this.weatherSnackbar.color   = 'error';
          this.weatherSnackbar.timeout = 6000;
          this.weatherSnackbar.show    = true;
        }
      },
      async clearWeather(index) {
        this.updateSiteLogField(null, 'vremenske_razmere.' + index + '.vreme');
        this.updateSiteLogField(null, 'vremenske_razmere.' + index + '.temperatura');
        this.updateSiteLogField(null, 'vremenske_razmere.' + index + '.padavine_mm');
        this.updateSiteLogField(null, 'vremenske_razmere.' + index + '.vodostaj_cm');
        this.updateSiteLogField(null, 'vremenske_razmere.' + index + '.hitrost_vetra_ms');
        this.updateSiteLogField(null, 'vremenske_razmere.' + index + '.drugi_pogoji');

      },
      async copyFromPreviousSiteLog(fieldPath) {
        const projectId  = this.$route.params.projectId;
        const beforeDate = this.$route.params.journalDate;

        this.$store.dispatch('copyFieldFromPreviousSiteLog', { projectId, beforeDate, fieldPath });
      },

      /**
       * Export
       */
      async exportAsPdf() {
        const url = `${this.$config.API_PDF_EXPORT_BASE}/dnevni-list/${this.$route.params.projectId}/${this.$route.params.journalDate},${this.sequence}?withPhotos=${this.printWithImages}`;

        window.open(url, '_blank');
      },
    }
  }
</script>

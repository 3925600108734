<template>
  <v-container
      grid-list-sm
      fluid
  >
    <v-dialog
        v-model="imageEdit.dialogOpen"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
                <span class="headline">
                  Urejanje slike
                </span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-text-field
                v-model="imageEdit.imageData.description"
                label="Opis slike"
            />
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
              color="red darken-1"
              flat
              @click="deleteImage"
          >
            Izbriši sliko
          </v-btn>
          <v-btn
              color="blue darken-1"
              flat
              @click="imageEdit.dialogOpen = false"
          >
            Zapri
          </v-btn>
          <v-btn
              color="blue darken-1"
              flat
              @click="updateImage()"
          >
            Shrani
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <lightbox
        id="journalImagesLightbox"
        ref="journalImagesLightbox"
        :images="lightboxImages"
        :timeout-duration="5000"
    />

    <h2 v-show="images.length > 0">{{ date | humanReadableDate }}</h2>
    <v-layout
        row
        wrap
    >
      <v-flex
          v-for="photo in images"
          :key="photo.image_id"
          xs4
          d-flex
      >
        <v-card
            class="mx-auto"
        >
          <v-card-text>
            <v-img
                :src="photo.thumbs[256][0]"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="showLightbox(photo)"
            >
              <v-layout
                  slot="placeholder"
                  fill-height
                  align-center
                  justify-center
                  ma-0
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                />
              </v-layout>
            </v-img>
          </v-card-text>

          <v-card-actions v-if="canEditProject">

            <v-btn
                fab
                @click="openEditImageDialog(photo)"
            >
              <v-icon>edit</v-icon>
            </v-btn>

            <v-btn
                fab
                @click="downloadImage(photo)"
            >
              <v-icon>download</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<style>
.lightbox-caption {
  font-size: 28px;
}

.lightbox-arrow-left-icon svg, .lightbox-arrow-right-icon svg {
  width: 48px;
  height: 48px;
}
</style>

<script>
import moment from 'moment';
import router from '@/router';

export default {
  name: 'JournalImages',
  filters: {
    humanReadableDate: (date) => {
      return moment(date).format('dddd, DD.MM.YYYY');
    }
  },
  props: ['projectId', 'date', 'canEditProject', 'images'],
  watch: {
    '$route': function (route) {
      if (!this.$refs.journalImagesLightbox) return;

      if ('string' === typeof route.query.imageUrl) {
        this.$refs.journalImagesLightbox.show(route.query.imageUrl);
        this.checkLightBoxIfOpen();
      } else {
        this.$refs.journalImagesLightbox.hide();

        clearInterval(this.checkLightBoxOpenInterval);
      }
    }
  },
  mounted() {
    if ('string' === typeof this.$route.query.imageUrl) {
      this.$refs.journalImagesLightbox.show(this.$route.query.imageUrl);
      this.checkLightBoxIfOpen();
    }
  },
  data() {
    return {
      imageEdit: {
        dialogOpen    : false,
        dateDialogOpen: false,
        imageData     : {}
      },

      // Lightbox
      checkLightBoxOpenInterval: null,
    };
  },
  computed: {
    lightboxImages() {
      return this.images
          .map(i => {
            const humanReadableDate = moment(i.date).format('dddd, DD.MM.YYYY');

            return {
              name: i.thumbs[1024][0],
              alt : `${humanReadableDate}: ${i.description || 'Ni opisa'}`,
            };
          })
        ;
    },
  },
  methods: {
    goTo(href) {
      router.push(href);
    },
    showLightbox(image) {
      const imageUrl = image.thumbs[1024][0];
      this.goTo(`${this.$route.path}?imageUrl=${encodeURIComponent(imageUrl)}`);
    },
    checkLightBoxIfOpen() {
      this.checkLightBoxOpenInterval = setInterval(() => {
        if (this.$refs.journalImagesLightbox && !this.$refs.journalImagesLightbox.visible) {
          this.goTo(this.$route.path);
        }
      }, 1000);
    },
    openEditImageDialog(image) {
      this.imageEdit.dialogOpen = true;
      this.imageEdit.imageData  = Object.assign({}, image);
    },
    downloadImage(image) {
      window.open(image.download_url, '_blank');
    },
    async updateImage() {
      this.imageEdit.dialogOpen = false;

      const projectId   = this.$route.params.projectId;
      const imageId     = this.imageEdit.imageData.image_id;
      const date        = this.imageEdit.imageData.date;
      const description = this.imageEdit.imageData.description;

      await this.$store.dispatch('updateSiteLogImage', {
        projectId,
        imageId,
        date,
        description
      });
    },
    async deleteImage() {
      this.imageEdit.dialogOpen = false;

      const projectId = this.$route.params.projectId;
      const imageId   = this.imageEdit.imageData.image_id;

      await this.$store.dispatch('deleteSiteLogImage', {
        projectId,
        imageId
      });
    },
  }
}
</script>

<style scoped>

</style>

import firebase from 'firebase';

const CheckUserCredentials = {
  install(Vue, options) {
    const router = options.router;

    firebase.auth().onAuthStateChanged((user) => {
      if (
        !user &&
        '/register' !== router.currentRoute.path &&
        '/login' !== router.currentRoute.path &&
        '/forgotten' !== router.currentRoute.path &&
        '/sign' !== router.currentRoute.path.substr(0, 5)
      ) {
        window.localStorage.clear();
        router.push('/login');
      }
    });
  }
};


export default CheckUserCredentials;
